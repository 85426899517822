import React from 'react';
import {Space, Table, Typography} from 'antd';
import {type MaterialPurchaseOrder} from '../MaterialPurchaseOrder/material-purchase-order';
import {getCurrencyString, getS3Url} from 'app/helpers';
import {PaperClipOutlined} from '@ant-design/icons';

const {Text} = Typography;

type Props = {
	purchaseOrders: MaterialPurchaseOrder[];
};

const PurchaseOrderView: React.FC<Props> = ({purchaseOrders}) => {
	const columns = [
		{
			title: 'Item',
			dataIndex: ['item', 'name'],
			key: 'item',
		},
		{
			title: 'Cost Head',
			dataIndex: ['costHead', 'name'],
			key: 'costHead',
		},
		{
			title: 'Vendor',
			dataIndex: ['vendor', 'name'],
			key: 'vendor',
		},
		{
			title: 'Attachments',
			key: 'attachments',
			render: (_, record: MaterialPurchaseOrder) => (
				<Space direction='vertical'>
					{record.attachments?.length
						? record.attachments.map((attachment, ix: number) => (
							<a
								key={ix}
								href={getS3Url(attachment.key ?? '')}
								target='_blank'
								rel='noreferrer'
							>
								<PaperClipOutlined /> {attachment.name}
							</a>
						))
						: '-'}
				</Space>
			),
		},
		{
			title: 'Base Price',
			dataIndex: 'basePrice',
			key: 'basePrice',
			align: 'right' as const,
			render: (price: number) => getCurrencyString(price, false),
		},
		{
			title: 'GST %',
			dataIndex: 'gstPercent',
			key: 'gstPercent',
			align: 'right' as const,
			render: (gst: number) => `${gst}%`,
		},
		{
			title: 'Total Cost',
			dataIndex: 'totalCost',
			key: 'totalCost',
			align: 'right' as const,
			render: (total: number) => getCurrencyString(total, false),
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={purchaseOrders}
			rowKey='id'
			pagination={false}
			summary={pageData => {
				const total = pageData.reduce((sum, po) => sum + (po.totalCost || 0), 0);
				return (
					<Table.Summary fixed>
						<Table.Summary.Row>
							<Table.Summary.Cell index={0} colSpan={6}>
								<Text strong>Total:</Text>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={1} align='right'>
								<Text strong>{getCurrencyString(total, false)}</Text>
							</Table.Summary.Cell>
						</Table.Summary.Row>
					</Table.Summary>
				);
			}}
		/>
	);
};

export default PurchaseOrderView;
