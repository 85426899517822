import React from 'react';
import {
	Col,
	Empty,
	Row,
	Spin,
	Button,
	Badge,
	Card,
	Space,
	Form,
	Select,
	Modal,
	Input,
	message,
} from 'antd';
import {
	CommentParentType,
	QueryStatus,
	moduleCommentType,
	UserType,
	VisibilityType,
	type Query,
} from 'app/models';
import {CommentBlock} from '../Common/CommentBlock';
import {type UploadFile} from 'app/models/upload-file';
import {FileUpload} from '../Common/FileUpload';
import {IssuesCloseOutlined} from '@ant-design/icons';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {
	parseNum,
	toTitleCase,
	equalNum,
	checkFileErr,
	formatDateFunction,
} from 'app/helpers';
import {getById, reassign, close, reOpen, escalate} from './actions';
import {
	get as getComments,
	save as saveComment,
} from 'app/components/Common/comment-actions';
import {queryEscalateAfterDays, uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {type FilterOptionItem} from 'app/models/ui-filter';

type ParamType = {
	id?: string;
};

// eslint-disable-next-line complexity
export const ViewWarrantyQueryDetail: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<ParamType>();
	const queryId = parseNum(params?.id ?? '0');

	const before3Days = new Date(
		new Date().setDate(new Date().getDate() - queryEscalateAfterDays),
	);

	const {user: authUser, userOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {loading, byIds, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.warrantyQuery,
	);
	const {
		allIds: commentAllIds,
		byIds: commentByIds,
		dataUpdated: commentDataUpdated,
	} = useSelector((state: Types.RootState) => state.comment);

	const comments = commentAllIds
		? commentAllIds.map(id => commentByIds[id])
		: [];
	const query = byIds[queryId];
	const userType = authUser?.userType;
	const statusColor = query?.status === QueryStatus.OPEN ? 'green' : 'red';

	const filteredComments = comments?.length
		? comments
			.filter(
				comment =>
					comment.createdBy === parseNum(authUser?.id)
					|| !comment.visibility
					|| (comment.visibility
						&& comment.visibility === VisibilityType.EVERYONE)
					|| (comment.visibility
						&& comment.visibility === VisibilityType.INTERNAL
						&& userType !== UserType.CLIENT
						&& userType !== UserType.VENDOR)
					|| (comment.visibility
						&& comment.visibility === VisibilityType.CLIENT
						&& userType !== UserType.VENDOR)
					|| (comment.visibility
						&& comment.visibility === VisibilityType.VENDOR
						&& userType !== UserType.CLIENT),
			)
			.sort((a, b) => {
				if (a.createdAt && b.createdAt) {
					return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
				}

				return -1;
			})
		: [];

	const [showReassignModal, setShowReassignModal] = React.useState<boolean>();
	const [showCloseModal, setShowCloseModal] = React.useState<boolean>();
	const [showReOpenModal, setShowReOpenModal] = React.useState<boolean>();
	const [showEscalateModal, setShowEscalateModal]
		= React.useState<boolean>(false);
	const [reassignTo, setReassignTo] = React.useState<number | undefined>(
		undefined,
	);
	const [reassignReason, setReassignReason] = React.useState<
	string | undefined
	>(undefined);
	const [closeReason, setCloseReason] = React.useState<string | undefined>(
		undefined,
	);
	const [closeFileList, setCloseFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<
	Record<string, string>
	>({});
	const [reOpenReason, setReOpenReason] = React.useState<string | undefined>(
		undefined,
	);
	const [reOpenFileList, setReOpenFileList] = React.useState<UploadFile[]>([]);
	const [escalatePoint, setEscalatePoint] = React.useState<string | undefined>(
		undefined,
	);

	React.useEffect(() => {
		if (queryId) {
			dispatch(getById(queryId));
			dispatch(
				getComments({
					where: {
						parentId: queryId,
						parentType: CommentParentType.WARRANTY_QUERY,
					},
				}),
			);
		}
	}, [queryId]);

	React.useEffect(() => {
		if (queryId && dataUpdated) {
			dispatch(getById(queryId));
			dispatch(
				getComments({
					where: {
						parentId: queryId,
						parentType: CommentParentType.WARRANTY_QUERY,
					},
				}),
			);
		}
	}, [queryId, dataUpdated]);

	React.useEffect(() => {
		if (queryId && commentDataUpdated) {
			dispatch(getById(queryId));
			dispatch(
				getComments({
					where: {
						parentId: queryId,
						parentType: CommentParentType.WARRANTY_QUERY,
					},
				}),
			);
		}
	}, [queryId, commentDataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSaveComment = data => dispatch(saveComment(data));

	const onReassignClick = () => {
		setShowReassignModal(true);
	};

	const onCloseQueryClick = () => {
		setShowCloseModal(true);
	};

	const onReOpenQueryClick = () => {
		if (query.closedAt) {
			const closedDate = new Date(query.closedAt);
			const currentDate = new Date();
			const daysDifference = Math.floor(
				(currentDate.getTime() - closedDate.getTime()) / (1000 * 60 * 60 * 24),
			);

			if (daysDifference > 7) {
				void message.error(
					'Queries can only be reopened within 7 days of closure. Please raise a new query if required.',
				);
				return;
			}
		}

		setShowReOpenModal(true);
	};

	const onEscalateQueryClick = () => {
		setShowEscalateModal(true);
	};

	const handleReassignQueryOk = () => {
		if (!reassignTo) {
			void message.error('Please select a user to reassign this query to.');
			return;
		}

		if (!reassignReason) {
			void message.error('Please give a reason for reassigning this query.');
			return;
		}

		const data = {
			content: reassignReason,
			reassignTo: parseNum(reassignTo),
		};

		dispatch(reassign(query.id, data));
		setReassignReason(undefined);
		setReassignTo(undefined);
		setShowReassignModal(false);
	};

	const handleReassignQueryCancel = () => {
		setReassignReason(undefined);
		setReassignTo(undefined);
		setShowReassignModal(false);
	};

	const handleCloseQueryOk = () => {
		if (!closeReason) {
			void message.error('Please enter a comment.');
			return;
		}

		checkFileErr(closeFileList);

		const attachments = closeFileList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));

		const data = {
			content: closeReason,
			attachments,
		};

		dispatch(close(query.id, data));
		setCloseReason(undefined);
		setShowCloseModal(false);
		setCloseFileList([]);
		setFilePrefixKeys({});
	};

	const handleCloseQueryCancel = () => {
		setCloseReason(undefined);
		setShowCloseModal(false);
	};

	const handleReOpenQueryOk = () => {
		if (!reOpenReason) {
			void message.error('Please enter a comment.');
			return;
		}

		checkFileErr(reOpenFileList);

		const attachments = reOpenFileList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));

		const data = {
			content: reOpenReason,
			attachments,
		};

		dispatch(reOpen(query.id, data));
		setReOpenReason(undefined);
		setShowReOpenModal(false);
		setReOpenFileList([]);
		setFilePrefixKeys({});
	};

	const handleReOpenQueryCancel = () => {
		setReOpenReason(undefined);
		setShowReOpenModal(false);
	};

	const handleEscalateQueryOk = (escalatedTo?: number) => {
		if (!escalatedTo) {
			return;
		}

		if (!escalatePoint) {
			void message.error('Please enter an escalate point.');
			return;
		}

		const data = {
			content: escalatePoint,
			escalatedTo,
		};

		dispatch(escalate(query.id, data));
		setEscalatePoint(undefined);
		setShowEscalateModal(false);
	};

	const handleEscalateQueryCancel = () => {
		setEscalatePoint(undefined);
		setShowEscalateModal(false);
	};

	/**
   * Query is null when there is no query found based on the id in the url.
   * In this case, we display an empty page.
   */
	if (!query) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<DefaultLayout currentPath={uiPaths.warrantyQueryDetail}>
				{query ? (
					<Row>
						<Col span={24}>
							<Button onClick={() => {
								history.goBack();
							}}>Back</Button>
							<h3 style={{textAlign: 'center'}}>{query.site?.name}</h3>
						</Col>
						<Col span={24} className='mt-15'>
							<Badge.Ribbon
								style={{height: 25}}
								color={statusColor}
								text={toTitleCase(query.status, '_')}
							>
								<Card size='small'>
									<CommentBlock
										parentId={query.id}
										parentType={moduleCommentType.WARRANTY_QUERY}
										authUser={authUser}
										comments={filteredComments}
										canComment={query.status === QueryStatus.OPEN}
										saveComment={data => onSaveComment(data)}
										showVisibility={
											userType !== UserType.CLIENT
                    && userType !== UserType.VENDOR
										}
									/>

									<Row>
										<Col
											span={24}
											className='mt-15'
											style={{textAlign: 'right'}}
										>
											<Space wrap={true}>
												{query.assignedUser?.name ? (
													<span>
														<b>Currently Assigned To:</b>{' '}
														{query.assignedUser.name ?? ''}
													</span>
												) : null}
												{(equalNum(authUser?.id, query.createdBy)
                        || equalNum(authUser?.id, query.assignedTo))
                      && userType === UserType.EMPLOYEE
                      && query.status !== QueryStatus.CLOSED ? (
														<Button type='primary' onClick={onReassignClick}>
                            Reassign this Query
														</Button>
													) : null}
												{equalNum(authUser?.id, query.assignedTo)
                      || equalNum(authUser?.id, query.createdBy) ? (
														query.status === QueryStatus.OPEN ? (
															<Button
																type='primary'
																danger={true}
																onClick={onCloseQueryClick}
															>
																<IssuesCloseOutlined /> Mark this Query as Closed
															</Button>
														) : (
															<Button type='primary' onClick={onReOpenQueryClick}>
                              Re-open this Query
															</Button>
														)
													) : null}
											</Space>
										</Col>
									</Row>
								</Card>
							</Badge.Ribbon>
						</Col>

						<Col span={24} className='mt-15'>
							<Card size='small' title={'Please Note'}>
								{`If you want to edit or delete the raised query or assigned it to
              a different person, mark the raised query as 'Closed' and raise
              a fresh query.`}
							</Card>
						</Col>
						{query.createdBy === authUser?.id ? (
							<EscalateActionBlock
								query={query}
								before3Days={before3Days}
								onEscalateQueryClick={onEscalateQueryClick}
							/>
						) : []}

						<Modal
							title='Reassign this query'
							open={showReassignModal}
							onOk={handleReassignQueryOk}
							onCancel={handleReassignQueryCancel}
						>
							<Form layout='vertical'>
								<Form.Item label='For Whom to Assign'>
									<Select
										showSearch={true}
										style={{width: '100%'}}
										placeholder='Select For Whom to Assign'
										optionFilterProp='children'
										onChange={value => {
											setReassignTo(value as number);
										}}
									>
										{[...userOptions].map((option: FilterOptionItem, ix: number) => (
											<Select.Option key={ix} value={option.value}>
												{option.label}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label='Please give a reason for reassigning'>
									<Input.TextArea
										placeholder={'Enter Reason'}
										defaultValue={reassignReason ?? ''}
										value={reassignReason ?? ''}
										onChange={(e: any) => {
											setReassignReason(String(e.target.value));
										}}
									/>
								</Form.Item>
							</Form>
						</Modal>
						<Modal
							title='Close this query'
							open={showCloseModal}
							onOk={handleCloseQueryOk}
							onCancel={handleCloseQueryCancel}
						>
							<Form layout='vertical'>
								<Form.Item label='Please enter a comment.'>
									<Input.TextArea
										placeholder={'Enter comment'}
										defaultValue={closeReason ?? ''}
										value={closeReason ?? ''}
										onChange={(e: any) => {
											setCloseReason(String(e.target.value));
										}}
									/>
								</Form.Item>
								<Form.Item label='Upload attachments'>
									<FileUpload
										label={'Click to Upload'}
										prefix={'comment'}
										fileList={closeFileList}
										filePrefixKeys={filePrefixKeys}
										onFileChange={(closeFileList, filePrefixKeys) => {
											setCloseFileList(closeFileList);
											setFilePrefixKeys(filePrefixKeys);
										}}
									/>
								</Form.Item>
							</Form>
						</Modal>
						<Modal
							title='Re-open this query'
							open={showReOpenModal}
							onOk={handleReOpenQueryOk}
							onCancel={handleReOpenQueryCancel}
						>
							<Form layout='vertical'>
								<Form.Item label='Please enter a comment.'>
									<Input.TextArea
										placeholder={'Enter comment'}
										defaultValue={reOpenReason ?? ''}
										value={reOpenReason ?? ''}
										onChange={(e: any) => {
											setReOpenReason(String(e.target.value));
										}}
									/>
								</Form.Item>
								<Form.Item label='Upload attachments'>
									<FileUpload
										label={'Click to Upload'}
										prefix={'comment'}
										fileList={reOpenFileList}
										filePrefixKeys={filePrefixKeys}
										onFileChange={(reOpenFileList, filePrefixKeys) => {
											setReOpenFileList(reOpenFileList);
											setFilePrefixKeys(filePrefixKeys);
										}}
									/>
								</Form.Item>
							</Form>
						</Modal>
						<Modal
							title='Escalate this Query'
							open={showEscalateModal}
							onOk={() => {
								handleEscalateQueryOk(query.escalateToUser?.id);
							}}
							onCancel={handleEscalateQueryCancel}
						>
							<Form layout='vertical'>
								<Form.Item>
									Query is being Escalated to {query.escalateToUser?.name} (
									{toTitleCase(query.escalateToUser?.roles)})
								</Form.Item>
								<Form.Item label='Please Enter an Escalation Point'>
									<Input.TextArea
										placeholder={'Enter Escalation Point'}
										defaultValue={escalatePoint ?? ''}
										value={escalatePoint ?? ''}
										onChange={(e: any) => {
											setEscalatePoint(String(e.target.value));
										}}
									/>
								</Form.Item>
							</Form>
						</Modal>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</DefaultLayout>
		</Spin>
	);
};

type EscalateActionBlockProp = {
	query: Query;
	before3Days: Date;
	onEscalateQueryClick: () => void;
};

const EscalateActionBlock: React.FC<EscalateActionBlockProp> = ({query, before3Days, onEscalateQueryClick}) => (
	<Col span={24} className='mt-15'>
		<Card size='small' title={'Escalate this Query'}>
				Escalate to the seniors if, <br />
				- you don&apos;t get any reply or acknowledgment within 3 days of
				raising the query.
			<br />
				- you are not satisfied with the response.
			<br />
			<br />
			{new Date(query.assignedAt) > before3Days ? (
				<b>Query can not be escalated now.</b>
			) : query.escalateToUser?.id ? (
				<Button
					type={'primary'}
					size={'small'}
					onClick={onEscalateQueryClick}
				>
					Escalate this Query to {query.escalateToUser?.name} (
					{toTitleCase(query.escalateToUser?.roles)})
				</Button>
			) : (
				<b>Query cannot be escalated any further.</b>
			)}
			{<br />}
			{query.isEscalated ? (
				<span>
					Query last escalated to {query.assignedUser?.name} (
					{toTitleCase(query.assignedUser?.roles)}) at{' '}
					{formatDateFunction(query.assignedAt, true)}.
				</span>
			) : (
				[]
			)}
			<br />
			<br />
			{query.isEscalated ? (
				<p>
					<b>
						Please note that a query can be escalated again after{' '}
						{queryEscalateAfterDays} days of last escalation to
						more senior team member.
					</b>
				</p>
			) : (
				[]
			)}
		</Card>
	</Col>
);
