import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Row,
	Select,
	Spin,
	Switch,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {getById, create, update, getParents} from './actions';
import {useHistory, useParams} from 'react-router';
import {parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {type MaterialMaster} from './material-master';
import {type FilterOptionItem} from 'app/models/ui-filter';

type MyParams = {
	id?: string;
};

export const ViewMaterialMasterForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();

	const {loading, byIds, parents, dataUpdated, errorMessage}
        = useSelector((state: Types.RootState) => state.materialMaster);

	const id = parseNum(params?.id);
	const material = byIds[id] ?? undefined;

	const [name, setName] = React.useState(
		material?.name ?? '',
	);
	const [parentId, setParentId] = React.useState(
		material?.parentId ?? 0,
	);
	const [isCostHead, setIsCostHead] = React.useState(
		material?.isCostHead ?? false,
	);
	const [costHeadIds, setCostHeadIds] = React.useState<string[]>(
		material?.costHeadIds ? material.costHeadIds.split(',') : [],
	);
	const [validationErrors, setValidationErrors] = React.useState<{
		parentId?: string;
	}>({});

	React.useEffect(() => {
		dispatch(getParents());
	}, []);

	React.useEffect(() => {
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (id) {
				void message.success('Updated Successfully.');
				dispatch(getById(id));
			} else {
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	// Reset parent when isCostHead is toggled to true
	React.useEffect(() => {
		if (isCostHead && parentId) {
			setParentId(0);
			setValidationErrors(prev => ({
				...prev,
				parentId: 'Cost head items cannot have a parent',
			}));
		} else {
			setValidationErrors(prev => ({
				...prev,
				parentId: undefined,
			}));
		}
	}, [isCostHead]);

	const handleCostHeadIdsChange = (values: string[]) => {
		if (values.includes('selectAll')) {
			const allCostHeadIds = parents
				.filter(item => item.isCostHead)
				.map(item => String(item.id));
			setCostHeadIds(allCostHeadIds);
		} else {
			setCostHeadIds(values);
		}
	};

	const handleParentChange = (value: string) => {
		const newParentId = parseNum(value);
		if (isCostHead && newParentId) {
			void message.error('Cost head items cannot have a parent');
			return;
		}

		setParentId(newParentId);
		setValidationErrors(prev => ({
			...prev,
			parentId: undefined,
		}));
	};

	const validateForm = (): boolean => {
		const errors: {parentId?: string} = {};

		if (isCostHead && parentId) {
			errors.parentId = 'Cost head items cannot have a parent';
		}

		setValidationErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const onSubmitClicked = () => {
		if (!name) {
			void message.error('Please enter a name!');
			return;
		}

		if (!validateForm()) {
			void message.error('Please fix the validation errors before submitting');
			return;
		}

		const data: Omit<MaterialMaster, 'id'> = {
			parentId,
			name,
			isCostHead,
			costHeadIds: costHeadIds.join(','),
		};

		if (material?.id) {
			dispatch(update(material.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	// Filter out cost heads from parent options
	const parentOptions: FilterOptionItem[] = parents
		.filter(item => !item.isCostHead)
		.map(item => ({
			value: String(item.id),
			label: item.name,
		}));

	const costHeadOptions = parents
		.filter(item => item.isCostHead)
		.map(item => ({
			value: String(item.id),
			label: item.name,
		}));

	return (
		<DefaultLayout currentPath={uiPaths.addMaterialMaster}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
                            Material Master Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
                            Back
						</Button>
						<Form {...layout} name='basic'>
							<Form.Item
								label='Is Cost Head'
								help='Enable if this material is a cost head'
							>
								<Switch
									checked={isCostHead}
									onChange={checked => {
										setIsCostHead(checked);
									}}
								/>
							</Form.Item>

							<Form.Item
								label='Parent (If Any)'
								name='parentId'
								validateStatus={validationErrors.parentId ? 'error' : ''}
								help={validationErrors.parentId}
							>
								<Select
									showSearch={true}
									optionFilterProp='children'
									placeholder='Select a Parent'
									value={parentId ? String(parentId) : undefined}
									onChange={handleParentChange}
									disabled={isCostHead}
								>
									{parentOptions.map((item: FilterOptionItem, ix: number) => (
										<Select.Option key={ix} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label='Name'
								rules={[{required: true, message: 'Please Enter Name'}]}
							>
								<Input
									value={name}
									onChange={(e: any) => {
										setName(String(e.target.value));
									}}
								/>
							</Form.Item>

							<Form.Item label='Cost Head Items'>
								<Select
									mode='multiple'
									style={{width: '100%'}}
									placeholder='Select Cost Head Items'
									optionFilterProp='children'
									value={costHeadIds}
									onChange={handleCostHeadIdsChange}
								>
									<Select.Option key='selectAll' value='selectAll'>
                                        Select All
									</Select.Option>
									{costHeadOptions.map(item => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
                                    Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
