import {type User} from 'app/models/user.model';

export type Attachment = {
	id: number;
	name: string;
	description?: string;
	key?: string;
	fileUrl?: string;
	parentId?: string;
	parentType?: AttachmentParentType;
	createdBy?: number;
	createdAt?: string;
	createdUser?: User;
	s3Url?: string;
};

export type AttachmentData = {
	name: string;
	description?: string;
	key?: string;
	fileUrl?: string;
	parentId?: string;
	parentType?: AttachmentParentType;
	createdBy?: number;
	createdAt?: string;
	createdUser?: User;
	s3Url?: string;
};

export enum AttachmentParentType {
	QUERY = 'QUERY',
	PETTY_CASH = 'PETTY_CASH',
	IN_PROGRESS_PROJECT_SCHEDULE = 'IN_PROGRESS_PROJECT_SCHEDULE',
	COMPLETED_PROJECT_SCHEDULE = 'COMPLETED_PROJECT_SCHEDULE',
	PROCESS_CHECKLIST_QUESTION_RESPONSE = 'PROCESS_CHECKLIST_QUESTION_RESPONSE',
	MATERIAL_QUERY_RECEIVED_OPTION = 'MATERIAL_QUERY_RECEIVED_OPTION',
	MATERIAL_QUERY_RECEIVED_DEFECTIVE_ITEM = ' MATERIAL_QUERY_RECEIVED_DEFECTIVE_ITEM',
	MATERIAL_QUERY_DELIVERY_CHALLAN = 'MATERIAL_QUERY_DELIVERY_CHALLAN',
	MATERIAL_QUERY_MATERIAL_PHOTOS = 'MATERIAL_QUERY_MATERIAL_PHOTOS',
	MATERIAL_QUERY_MATERIAL_LENGTH = 'MATERIAL_QUERY_MATERIAL_LENGTH',
	MATERIAL_QUERY_MATERIAL_BREADTH = 'MATERIAL_QUERY_MATERIAL_BREADTH',
	MATERIAL_QUERY_MATERIAL_HEIGHT = 'MATERIAL_QUERY_MATERIAL_HEIGHT',
	// Adding work order fields
	WORK_ORDER_SCOPE_OF_WORK = 'WORK_ORDER_SCOPE_OF_WORK',
	WORK_ORDER_MATERIAL_CONTRACTOR = 'WORK_ORDER_MATERIAL_CONTRACTOR',
	WORK_ORDER_MATERIAL_PRITHU = 'WORK_ORDER_MATERIAL_PRITHU',
	WORK_ORDER_ITEM_RATELIST = 'WORK_ORDER_ITEM_RATELIST',
	WORK_ORDER_PROJECT_SCHEDULE = 'WORK_ORDER_PROJECT_SCHEDULE',
	WORK_ORDER_QUALITY_DEFECT = 'WORK_ORDER_QUALITY_DEFECT',
	WORK_ORDER_QUALITY_CHECK = 'WORK_ORDER_QUALITY_CHECK',
	WORK_ORDER_PAYMENT_SCHEDULE = 'WORK_ORDER_PAYMENT_SCHEDULE',
	WORK_ORDER_SCHEDULE_ATTACHMENT = 'WORK_ORDER_SCHEDULE_ATTACHMENT',
	WORK_ORDER_CONTACT_PERSON = 'WORK_ORDER_CONTACT_PERSON',
	WORK_ORDER_TERM_OF_AGREEMENT = 'WORK_ORDER_TERM_OF_AGREEMENT',
	WORK_ORDER_GENERAL_CLAUSES = 'WORK_ORDER_GENERAL_CLAUSES',
	WORK_ORDER_DRAWING_FILES = 'WORK_ORDER_DRAWING_FILES',
	WORK_ORDER_DRAWING_CALCULATION = 'WORK_ORDER_DRAWING_CALCULATION',
	WORK_ORDER_QUANTITY_CALCULATION = 'WORK_ORDER_QUANTITY_CALCULATION',
	WORK_ORDER_QUALITY_PRICE = 'WORK_ORDER_QUALITY_PRICE',
	WORK_ORDER_TENDER_COMPARISON = 'WORK_ORDER_TENDER_COMPARISON',
	WORK_ORDER_OTHER_FILES = 'WORK_ORDER_OTHER_FILES',
	WORK_ORDER_TERMINATION_SUMMARY = 'WORK_ORDER_TERMINATION_SUMMARY',
	PROJECT_FILE = 'PROJECT_FILE',
	ELECTRICITY_CONSUMPTION = 'ELECTRICITY_CONSUMPTION',
	WARRANTY_DOCUMENTS = 'WARRANTY_DOCUMENTS',
	MATERIAL_PURCHASE_ORDER = 'MATERIAL_PURCHASE_ORDER',
}
