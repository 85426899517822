import {ModuleName} from './module.model';
export type EmailTemplate = {
	id: number;
	content: string;
	header: string;
	moduleName: ModuleName;
	triggerPoint: TriggerPoint;
	triggerToWhom: string;
};

export enum TriggerPoint {
	CREATED = 'CREATED',
	CREATOR_REPLIED = 'CREATOR_REPLIED',
	ASSIGNED_USER_REPLIED = 'ASSIGNED_USER_REPLIED',
	NOT_CLOSED_5_DAYS = 'NOT_CLOSED_5_DAYS',
	CREATOR_CLOSED = 'CREATOR_CLOSED',
	ASSIGNED_USER_CLOSED = 'ASSIGNED_USER_CLOSED',
	REASSIGNED = 'REASSIGNED',
	CREATOR_REOPENED = 'CREATOR_REOPENED',
	ASSIGNED_USER_REOPENED = 'ASSIGNED_USER_REOPENED',
	ESCALATED = 'ESCALATED',
	UPDATED = 'UPDATED',
	REVISED = 'REVISED',
	APPROVED = 'APPROVED',
	CANCELLED = 'CANCELLED',
	NOT_ORDERED_3_DAYS = 'NOT_ORDERED_3_DAYS',
	ORDERED = 'ORDERED',
	PENDING_RECEIVE = 'PENDING_RECEIVE',
	STATUS_CHANGED = 'STATUS CHANGED',
	CHECKLIST_FILLED = 'CHECKLIST_FILLED',
	CHECKLIST_APPROVED_L1 = 'CHECKLIST_APPROVED_L1',
	CHECKLIST_APPROVED_L2 = 'CHECKLIST_APPROVED_L2',
	CHECKLIST_REJECTED_L1 = 'CHECKLIST_REJECTED_L1',
	CHECKLIST_REJECTED_L2 = 'CHECKLIST_REJECTED_L2',
	DELAYED_START_2_DAYS = 'DELAYED_START_2_DAYS',
	DELAYED_COMPLETE_2_DAYS = 'DELAYED_COMPLETE_2_DAYS',
	CAD_FILE_UPLOADED = 'CAD_FILE_UPLOADED',
	DAILY_PROGRESS_NOT_FILLED = 'DAILY_PROGRESS_NOT_FILLED',
	DAILY_ASSIGNED_OPENED_QUERY_REMINDER = 'DAILY_ASSIGNED_OPENED_QUERY_REMINDER',
	APPROVED_L1 = 'APPROVED_L1',
	APPROVED_L2 = 'APPROVED_L2',
	REJECTED_L1 = 'REJECTED_L1',
	REJECTED_L2 = 'REJECTED_L2',
	APPOINTED = 'APPOINTED',
	PAID = 'PAID',
	COMMENTED = 'COMMENTED',
}

export enum TriggerToWhom {
	CREATOR = 'CREATOR',
	ASSIGNED_USER = 'ASSIGNED_USER',
	ASSIGNED_USER_AND_ALL_REPORTING_MANAGERS = 'ASSIGNED_USER_AND_ALL_REPORTING_MANAGERS',
	CONSTRUCTION_TEAM = 'CONSTRUCTION_TEAM',
	WORK_ORDER_TEAM = 'WORK_ORDER_TEAM',
	APPROVAL_L1_RIGHT_USERS = 'APPROVAL_L1_RIGHT_USERS',
	APPROVAL_L2_RIGHT_USERS = 'APPROVAL_L2_RIGHT_USERS',
	MATERIAL_ORDER_USER = 'CREATOR_AND_MATERIAL_ORDER_USER',
	MATERIAL_ORDER_TEAM = 'MATERIAL_ORDER_TEAM',
	SITE_INCHARGE = 'SITE_INCHARGE',
	EDIT_RIGHT_USERS = 'EDIT_RIGHT_USERS',
	APPROVAL_RIGHT_USERS = 'APPROVAL_RIGHT_USERS',
	VIEW_RIGHT_USERS = 'VIEW_RIGHT_USERS',
	APPROVAL_TEAM = 'APPROVAL_TEAM',
	SITE_INCHARGE_AND_REPORTING_MANAGER = 'SITE_INCHARGE_AND_REPORTING_MANAGER',
	DESIGN_TEAM = 'DESIGN_TEAM',
	APM = 'APM',
	ALL_ASSOCIATED_USERS = 'ALL_ASSOCIATED_USERS',
	UPDATE_STATUS_USERS = 'UPDATE_STATUS_USERS',
}

export const triggerPointMap: Record<string, Record<string, string>> = {
	[ModuleName.QUERY]: {
		[TriggerPoint.CREATED]: 'When a project query is created',
		[TriggerPoint.CREATOR_REPLIED]:
      'When a reply is added to a project query by the query raiser',
		[TriggerPoint.ASSIGNED_USER_REPLIED]:
      'When a reply is added to a project query by the user to whom the project query is assigned',
		[TriggerPoint.NOT_CLOSED_5_DAYS]:
      'When a project query is not closed in 5 working days',
		[TriggerPoint.CREATOR_CLOSED]:
      'When the status of the project query is marked as closed by the query raiser',
		[TriggerPoint.ASSIGNED_USER_CLOSED]:
      'When the status of the project query is marked as closed by the user to whom the query is assigned',
		[TriggerPoint.REASSIGNED]: 'When the project query is reassigned',
		[TriggerPoint.CREATOR_REOPENED]:
      'When the project query is re-opened by the query raiser',
		[TriggerPoint.ASSIGNED_USER_REOPENED]:
      'When the project query is re-opened by the user to whom the query was assigned',
	},
	[ModuleName.CORPORATE_QUERY]: {
		[TriggerPoint.CREATED]: 'When a corporate query is created',
		[TriggerPoint.CREATOR_REPLIED]:
      'When a reply is added to a corporate query by the query raiser',
		[TriggerPoint.ASSIGNED_USER_REPLIED]:
      'When a reply is added to a corporate query by the user to whom the corporate query is assigned',
		[TriggerPoint.CREATOR_CLOSED]:
      'When the status of the corporate query is marked as closed by the query raiser',
		[TriggerPoint.ASSIGNED_USER_CLOSED]:
      'When the status of the corporate query is marked as closed by the user to whom the query is assigned',
		[TriggerPoint.REASSIGNED]: 'When the corporate query is reassigned',
		[TriggerPoint.CREATOR_REOPENED]:
      'When the corporate query is re-opened by the query raiser',
		[TriggerPoint.ASSIGNED_USER_REOPENED]:
      'When the corporate query is re-opened by the user to whom the query was assigned',
	},
	[ModuleName.CLIENT_QUERY]: {
		[TriggerPoint.CREATED]: 'When a client query is created',
		[TriggerPoint.CREATOR_REPLIED]:
      'When a reply is added to a client query by the query raiser',
		[TriggerPoint.ASSIGNED_USER_REPLIED]:
      'When a reply is added to a client query by the user to whom the client query is assigned',
		[TriggerPoint.CREATOR_CLOSED]:
      'When the status of the client query is marked as closed by the query raiser',
		[TriggerPoint.ASSIGNED_USER_CLOSED]:
      'When the status of the client query is marked as closed by the user to whom the query is assigned',
		[TriggerPoint.REASSIGNED]: 'When the client query is reassigned',
		[TriggerPoint.CREATOR_REOPENED]:
      'When the client query is re-opened by the query raiser',
		[TriggerPoint.ASSIGNED_USER_REOPENED]:
      'When the client query is re-opened by the user to whom the query was assigned',
		[TriggerPoint.ESCALATED]:
      'When the client query is escalated',
		[TriggerPoint.DAILY_ASSIGNED_OPENED_QUERY_REMINDER]: 'Daily email reminder for open client queries assigned to the user',
	},
	[ModuleName.PROJECT_BUDGET_QUANTITIES]: {
		[TriggerPoint.CREATED]: 'When a new work activity is added',
		[TriggerPoint.UPDATED]: 'When the project quantity will be updated',
		[TriggerPoint.REVISED]: 'When the project quantity will be revised',
	},
	[ModuleName.MATERIAL_QUERY]: {
		[TriggerPoint.CREATED]: 'When a material query is raised by the Site Incharge/Project Architect or Work Order Incharge',
		[TriggerPoint.APPROVED]: 'When the raised material query has been approved',
		[TriggerPoint.CANCELLED]: 'When the raised material query has been cancelled',
		[TriggerPoint.NOT_ORDERED_3_DAYS]: 'If the material query request has been approved by the APM and order is not placed by Procurement Manager within 3 days',
		[TriggerPoint.ORDERED]: 'When the raised material query has been marked as ordered',
		[TriggerPoint.PENDING_RECEIVE]: 'When site incharge marks the material query as received for approval',
	},
	[ModuleName.APPROVAL_SCHEDULE]: {
		[TriggerPoint.STATUS_CHANGED]: 'When an approval schedule activity status gets changed',
		[TriggerPoint.CHECKLIST_FILLED]: 'When approval checklist is filled by Approval Incharge',
		[TriggerPoint.CHECKLIST_APPROVED_L1]: 'When approval checklist is approved by the Approval Head (Approval Level 1)',
		[TriggerPoint.CHECKLIST_REJECTED_L1]: 'When approval checklist is rejected by the Approval Head (Approval Level 1)',
		[TriggerPoint.DELAYED_START_2_DAYS]: 'When approval schedule work activity is not started and delayed from the planned date by 2 days',
		[TriggerPoint.DELAYED_COMPLETE_2_DAYS]: 'When approval schedule work activity is not completed and delayed from the planned date by 2 days',
	},
	[ModuleName.CONSTRUCTION_SCHEDULE]: {
		[TriggerPoint.STATUS_CHANGED]: 'When an activity status gets changed',
		[TriggerPoint.CHECKLIST_FILLED]: 'When a construction checklist is being filled by site incharge',
		[TriggerPoint.CHECKLIST_APPROVED_L1]: 'When a construction checklist is approved by the approval level 1',
		[TriggerPoint.CHECKLIST_APPROVED_L2]: 'When a construction checklist is approved by the approval level 2',
		[TriggerPoint.CHECKLIST_REJECTED_L1]: 'When a construction checklist is rejected by the approval level 1',
		[TriggerPoint.CHECKLIST_REJECTED_L2]: 'When a construction checklist is rejected by the approval level 2',
		[TriggerPoint.DELAYED_START_2_DAYS]: 'When work activity is not started and crossed planned date + 2 days',
		[TriggerPoint.DELAYED_COMPLETE_2_DAYS]: 'When work activity is started but not completed within ideal duration + 2 days',
	},
	[ModuleName.DRAWING_SCHEDULE]: {
		[TriggerPoint.STATUS_CHANGED]: 'When an drawing schedule activity status gets changed',
		[TriggerPoint.CHECKLIST_FILLED]: 'When drawing checklist is filled by Project Architect or Assistant Architect',
		[TriggerPoint.CHECKLIST_APPROVED_L1]: 'When drawing checklist is approved by Project Architect or Principal Architect (Approval Level 1)',
		[TriggerPoint.CHECKLIST_REJECTED_L1]: 'When drawing checklist is rejected by Project Architect or Principal Architect (Approval Level 1)',
		[TriggerPoint.DELAYED_START_2_DAYS]: 'When drawing schedule work activity is delayed',
		[TriggerPoint.CAD_FILE_UPLOADED]: 'When the CAD file of drawing schedule is uploaded ',
	},
	[ModuleName.MATERIAL_ORDER_DAILY_QUANTITY]: {
		[TriggerPoint.DAILY_PROGRESS_NOT_FILLED]: 'When the site incharge misses to fill the daily work progress',
	},
	[ModuleName.LABOUR_ATTENDANCE]: {
		[TriggerPoint.DAILY_PROGRESS_NOT_FILLED]:
		'Daily reminder at 11AM for unfilled labour attendance',
	},
	[ModuleName.USER_PERMISSIONS]: {
		[TriggerPoint.CREATED]: 'When a new user permission is created',
	},
	[ModuleName.PROJECT_DETAIL]: {
		[TriggerPoint.STATUS_CHANGED]: 'When the status of a project is changed',
	},
	[ModuleName.WORK_ORDER]: {
		[TriggerPoint.CREATED]: 'When a new work order is created',
		[TriggerPoint.APPROVED_L1]: 'When a new work order is approved at level 1',
		[TriggerPoint.APPROVED_L2]: 'When a new work order is approved at level 2',
		[TriggerPoint.REJECTED_L1]: 'When a new work order is rejected at level 1',
		[TriggerPoint.REJECTED_L2]: 'When a new work order is rejected at level 2',
	},
	[ModuleName.WORK_ORDER_QUERY]: {
		[TriggerPoint.CREATED]: 'When a new work order query is created',
		[TriggerPoint.APPROVED]: 'When a work order query is approved',
		[TriggerPoint.CANCELLED]: 'When a work order query is rejected',
		[TriggerPoint.APPOINTED]: 'When a work order query is marked as Work Order Appointed',
		[TriggerPoint.COMMENTED]: 'When a comment is added to the work order query',
	},
	[ModuleName.WORK_ORDER_BILL]: {
		[TriggerPoint.CREATED]: 'When a new contractor bill is created',
		[TriggerPoint.APPROVED]: 'When a contractor bill is approved',
		[TriggerPoint.CANCELLED]: 'When a contractor bill is rejected',
		[TriggerPoint.PAID]: 'When a contractor bill is paid',
	},
};

export const triggerToWhomMap: Record<string, Record<string, string>> = {
	[ModuleName.QUERY]: {
		[TriggerToWhom.ASSIGNED_USER]: 'To whom the project query is assigned',
		[TriggerToWhom.CREATOR]: 'To the project query raiser',
	},
	[ModuleName.CLIENT_QUERY]: {
		[TriggerToWhom.ASSIGNED_USER]: 'To whom the client query is assigned',
		[TriggerToWhom.CREATOR]: 'To the client query raiser',
		[TriggerToWhom.ASSIGNED_USER_AND_ALL_REPORTING_MANAGERS]: 'To whom the client query is assigned and all the reporting managers',
	},
	[ModuleName.CORPORATE_QUERY]: {
		[TriggerToWhom.ASSIGNED_USER]: 'To whom the corporate query is assigned',
		[TriggerToWhom.CREATOR]: 'To the corporate query raiser',
	},
	[ModuleName.PROJECT_BUDGET_QUANTITIES]: {
		[TriggerToWhom.WORK_ORDER_TEAM]: 'To Work Order Incharge and their reporting manager',
		[TriggerToWhom.CONSTRUCTION_TEAM]: 'To the Site Incharge, APM, Construction Incharge and Construction Head',
	},
	[ModuleName.MATERIAL_QUERY]: {
		[TriggerToWhom.APPROVAL_L1_RIGHT_USERS]: 'To the user at Approval Level 1',
		[TriggerToWhom.MATERIAL_ORDER_USER]: 'To the Procurement Incharge',
		[TriggerToWhom.CREATOR]: 'To the user who raised the material query',
		[TriggerToWhom.MATERIAL_ORDER_TEAM]: 'To the user defined at the role of Procurement Incharge and his reporting manager',
		[TriggerToWhom.SITE_INCHARGE]: 'To Site Incharge',
	},
	[ModuleName.APPROVAL_SCHEDULE]: {
		[TriggerToWhom.EDIT_RIGHT_USERS]: 'To all the users with editing rights',
		[TriggerToWhom.APPROVAL_RIGHT_USERS]: 'To all the users with approval rights',
		[TriggerToWhom.APPROVAL_L1_RIGHT_USERS]: 'To the Approval Head (Approval Level 1)',
		[TriggerToWhom.APPROVAL_TEAM]: 'To the Approval Incharge (who has editing rights) and his reporting manager',
	},
	[ModuleName.CONSTRUCTION_SCHEDULE]: {
		[TriggerToWhom.EDIT_RIGHT_USERS]: 'To all the users with editing rights',
		[TriggerToWhom.APPROVAL_RIGHT_USERS]: 'To all the users with approval rights',
		[TriggerToWhom.APPROVAL_L1_RIGHT_USERS]: 'To all the users at approval level 1',
		[TriggerToWhom.APPROVAL_L2_RIGHT_USERS]: 'To all the users at approval level 2',
		[TriggerToWhom.CONSTRUCTION_TEAM]: 'To the user who has editing rights and his reporting managers till the tree followed upto Construction head',
	},
	[ModuleName.DRAWING_SCHEDULE]: {
		[TriggerToWhom.EDIT_RIGHT_USERS]: 'To all the users with editing rights',
		[TriggerToWhom.APPROVAL_RIGHT_USERS]: 'To all the users with approval rights',
		[TriggerToWhom.APPROVAL_L1_RIGHT_USERS]: 'To all the users at approval level 1',
		[TriggerToWhom.SITE_INCHARGE_AND_REPORTING_MANAGER]: 'To the user at the role of site incharge and his reporting manager',
		[TriggerToWhom.DESIGN_TEAM]: 'To the Project Architect and his reporting managers till the tree followed upto Design Head',
		[TriggerToWhom.WORK_ORDER_TEAM]: 'To the user defined on the role of work order incharge and his reporting manager',
	},
	[ModuleName.MATERIAL_ORDER_DAILY_QUANTITY]: {
		[TriggerToWhom.APM]: 'To the APM',
		[TriggerToWhom.SITE_INCHARGE]: 'To the Site Incharge',
	},
	[ModuleName.LABOUR_ATTENDANCE]: {
		[TriggerToWhom.SITE_INCHARGE]: 'To the Site Incharge',
	},
	[ModuleName.USER_PERMISSIONS]: {
		[TriggerToWhom.ASSIGNED_USER]: 'To the user whom it is assigned',
	},
	[ModuleName.PROJECT_DETAIL]: {
		[TriggerToWhom.ALL_ASSOCIATED_USERS]: 'To all users with any permission right for any module related to this project',
	},
	[ModuleName.WORK_ORDER]: {
		[TriggerToWhom.VIEW_RIGHT_USERS]: 'To all the users with view rights',
		[TriggerToWhom.EDIT_RIGHT_USERS]: 'To all the users with edit rights',
	},
	[ModuleName.WORK_ORDER_QUERY]: {
		[TriggerToWhom.CREATOR]: 'To user who created the query',
		[TriggerToWhom.VIEW_RIGHT_USERS]: 'To all the users with view rights',
		[TriggerToWhom.EDIT_RIGHT_USERS]: 'To all the users with edit rights',
		[TriggerToWhom.APPROVAL_RIGHT_USERS]: 'To all the users with approval rights',
		[TriggerToWhom.SITE_INCHARGE_AND_REPORTING_MANAGER]: 'To the user at the role of site incharge and his reporting manager',
		[TriggerToWhom.UPDATE_STATUS_USERS]: 'To the users who have permission to update the status',
	},
	[ModuleName.WORK_ORDER_BILL]: {
		[TriggerToWhom.CREATOR]: 'To user who created the bill',
		[TriggerToWhom.VIEW_RIGHT_USERS]: 'To all the users with view rights',
		[TriggerToWhom.EDIT_RIGHT_USERS]: 'To all the users with edit rights',
		[TriggerToWhom.APPROVAL_RIGHT_USERS]: 'To all the users with approval rights',
		[TriggerToWhom.SITE_INCHARGE_AND_REPORTING_MANAGER]: 'To the user at the role of site incharge and his reporting manager',
		[TriggerToWhom.ASSIGNED_USER]: 'To the user at the role of site incharge and his reporting manager',
	},
};

export const topTemplate = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
  </head>
  <body>
  <p>Hi :USER_NAME</p>`;

export const bottomTemplate = `
 <a href=":REDIRECT_URL" target="_blank">Please Click Here to View.</a>
 <br />
 <br />
 <p>If above link is not accessible, then you can simply copy and paste the below URL into the browser:</p>
 <pre>:REDIRECT_URL</pre>
  <br/>
  <p>Regards,</p>
  <p>Prithu App Team</p>
  </body>
</html>`;
