import React, {useState} from 'react';
import {Input, Select, Button, Table} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {type MaterialPurchaseOrder} from '../MaterialPurchaseOrder/material-purchase-order';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type MaterialMaster} from '../MaterialMaster/material-master';
import {getCurrencyString, checkFileErr} from 'app/helpers';
import {FileUpload} from '../Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import {type AttachmentData} from 'app/models';

type Props = {
	materialQueryId: number;
	siteId: number;
	materialMasters: MaterialMaster[];
	vendors: FilterOptionItem[];
	onChange: (items: MaterialPurchaseOrder[]) => void;
	initialValues?: MaterialPurchaseOrder[];
};

const PurchaseOrderForm: React.FC<Props> = ({
	materialQueryId,
	siteId,
	materialMasters,
	vendors,
	onChange,
}) => {
	const [purchaseFileList, setPurchaseFileList] = useState<UploadFile[]>([]);
	const [purchaseFilePrefixKeys, setPurchaseFilePrefixKeys] = useState<Record<string, string>>({});

	const newItem: MaterialPurchaseOrder = {
		itemId: 0,
		costHeadId: 0,
		parentId: 0,
		quantity: 0,
		vendorId: 0,
		basePrice: 0,
		gstPercent: 0,
		totalCost: 0,
		id: 0,
		siteId,
		materialQueryId,
		attachments: [],
	};
	const [items, setItems] = useState<MaterialPurchaseOrder[]>([newItem]);

	// Get all cost heads (items where isCostHead is true)
	const allCostHeads = materialMasters.filter(item => item.isCostHead);

	// Get all available items (items with parentId)
	const availableItems = materialMasters.filter(item => item.parentId);

	// Function to get available cost heads for a specific item
	const getAvailableCostHeads = (itemId: number) => {
		const selectedItem = materialMasters.find(item => item.id === itemId);
		if (!selectedItem?.costHeadIds) {
			return [];
		}

		// Split the comma-separated costHeadIds and convert to numbers
		const allowedCostHeadIds = selectedItem.costHeadIds.split(',').map(id => Number(id.trim()));

		// Filter cost heads that are both in the allowed list and have isCostHead true
		return allCostHeads.filter(costHead => allowedCostHeadIds.includes(costHead.id));
	};

	const calculateTotal = (item: Partial<MaterialPurchaseOrder>): number => {
		const {basePrice = 0, quantity = 0, gstPercent = 0} = item;
		const baseAmount = basePrice * quantity;
		const gstAmount = (baseAmount * gstPercent) / 100;
		return Math.round(baseAmount + gstAmount);
	};

	const addItem = () => {
		const newItemWithDefaults = {
			...newItem,
			costHeadId: 0,
			parentId: 0,
		};
		setItems([...items, newItemWithDefaults]);
	};

	const removeItem = (index: number) => {
		const updatedItems = [...items];
		updatedItems.splice(index, 1);
		setItems(updatedItems);
		onChange(updatedItems);
	};

	const updateItem = (index: number, field: keyof MaterialPurchaseOrder, value: any) => {
		const updatedItems = [...items];
		const item = updatedItems[index];

		if (item) {
			if (field === 'itemId') {
				const selectedItem = materialMasters.find(m => m.id === Number(value));
				updatedItems[index] = {
					...item,
					itemId: Number(value),
					costHeadId: 0, // Reset cost head when item changes
					parentId: selectedItem?.parentId ?? 0, // Set parent ID from selected item
				};
			} else {
				updatedItems[index] = {
					...item,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					[field]: value,
				};
			}

			if (['basePrice', 'quantity', 'gstPercent'].includes(field)) {
				updatedItems[index].totalCost = calculateTotal(updatedItems[index]);
			}

			if (field === 'attachments') {
				checkFileErr(purchaseFileList);
				const attachments = purchaseFileList.map((file: UploadFile) => {
					const x: AttachmentData = {
						name: file.name,
						key: `${purchaseFilePrefixKeys[file.uid]}/${file.name}`,
					};
					return x;
				});
				updatedItems[index].attachments = attachments;
			}

			setItems(updatedItems);
			onChange(updatedItems);
		}
	};

	const getParentName = (parentId: number) => {
		const parent = materialMasters.find(item => item.id === parentId);
		return parent ? parent.name : 'N/A';
	};

	const columns = [
		{
			title: 'Item Name *',
			dataIndex: 'itemId',
			key: 'itemId',
			width: 160,
			render: (itemId: number, record: MaterialPurchaseOrder, index: number) => (
				<Select
					style={{width: '100%'}}
					value={itemId || undefined}
					onChange={value => {
						updateItem(index, 'itemId', value);
					}}
				>
					{availableItems.map(item => (
						<Select.Option key={item.id} value={item.id}>
							{item.name}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Cost Head *',
			dataIndex: 'costHeadId',
			key: 'costHeadId',
			width: 160,
			render(costHeadId: number, record: MaterialPurchaseOrder, index: number) {
				const availableCostHeads = getAvailableCostHeads(record.itemId);

				return (
					<Select
						style={{width: '100%'}}
						value={costHeadId || undefined}
						onChange={value => {
							updateItem(index, 'costHeadId', value);
						}}
						disabled={!record.itemId}
					>
						{availableCostHeads.map(head => (
							<Select.Option key={head.id} value={head.id}>
								{head.name}
							</Select.Option>
						))}
					</Select>
				);
			},
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			width: 100,
			render: (quantity: number, record: MaterialPurchaseOrder, index: number) => (
				<Input
					type='number'
					value={quantity || ''}
					onChange={e => {
						updateItem(index, 'quantity', Number(e.target.value));
					}}
					onKeyDown={e => {
						// Prevent decimal point input
						if (e.key === '.') {
							e.preventDefault();
						}
					}}
				/>
			),
		},
		{
			title: 'Vendor',
			dataIndex: 'vendorId',
			key: 'vendorId',
			width: 160,
			render: (vendorId: number, record: MaterialPurchaseOrder, index: number) => (
				<Select
					style={{width: '100%'}}
					value={vendorId || undefined}
					onChange={value => {
						updateItem(index, 'vendorId', value);
					}}
				>
					{vendors.map(vendor => (
						<Select.Option key={vendor.value} value={vendor.value}>
							{vendor.label}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Base Price (₹)',
			dataIndex: 'basePrice',
			key: 'basePrice',
			width: 100,
			render: (basePrice: number, record: MaterialPurchaseOrder, index: number) => (
				<Input
					type='number'
					value={basePrice || ''}
					onChange={e => {
						updateItem(index, 'basePrice', Number(e.target.value));
					}}
				/>
			),
		},
		{
			title: 'GST (%)',
			dataIndex: 'gstPercent',
			key: 'gstPercent',
			width: 100,
			render: (gstPercent: number, record: MaterialPurchaseOrder, index: number) => (
				<Input
					type='number'
					value={gstPercent || ''}
					onChange={e => {
						updateItem(index, 'gstPercent', Number(e.target.value));
					}}
				/>
			),
		},
		{
			title: 'Attachments',
			key: 'attachments',
			render: (_: any, record: MaterialPurchaseOrder, index: number) => (
				<FileUpload
					prefix={'purchase-order'}
					fileList={purchaseFileList}
					filePrefixKeys={purchaseFilePrefixKeys}
					onFileChange={(newFileList, newFilePrefixKeys) => {
						setPurchaseFileList(newFileList);
						setPurchaseFilePrefixKeys(newFilePrefixKeys);
						updateItem(index, 'attachments', newFileList);
					}}
				/>
			),
		},
		{
			title: 'Total Amount',
			dataIndex: 'totalCost',
			key: 'totalCost',
			render: (totalCost: number) => getCurrencyString(totalCost, false),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: MaterialPurchaseOrder, index: number) => (
				<Button
					type='link'
					danger
					icon={<DeleteOutlined />}
					onClick={() => {
						removeItem(index);
					}}
				/>
			),
		},
	];

	const totalOrderAmount = items.reduce((sum, item) => sum + item.totalCost, 0);

	return (
		<div style={{width: '100%'}}>
			<Table
				dataSource={items}
				columns={columns}
				pagination={false}
				rowKey='id'
				footer={() => (
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={addItem}
						>
							Add Item
						</Button>
						<strong>Total Order Amount: {getCurrencyString(totalOrderAmount, false)}</strong>
					</div>
				)}
			/>
		</div>
	);
};

export default PurchaseOrderForm;
