import {type Site} from './site.model';
import {type User} from './user.model';
import {type WorkOrder} from './work-order.model';
import {type PaymentMilestone} from './payment-milestone.model';
import {type Penalty} from './penalty.model';
import {type WorkOrderDebit} from './work-order-debit.model';
import {type ProjectProcessMaster} from 'app/components/ProjectProcessMaster/project-process-master';

export type WorkOrderBill = {
	id: number;
	siteId: number;
	workOrderId: number;
	projectProcessMasterId?: number;
	contractorId?: number;
	orderBillStatus: WorkOrderBillStatus;
	workOrderBillType?: WorkOrderBillType;
	cancelReason?: string;
	billAmount: number;
	billDescription?: string;
	billedToClient?: boolean;
	attachments: string;
	qualityCheck: boolean;
	workAsPerSchedule: boolean;
	contractorVisitAsPerWO: boolean;
	foremenAsPerWO: boolean;
	penalties: Penalty[];
	debits?: WorkOrderDebit[];
	quantityMeasuredOnSite?: string;
	debitedToContractor?: boolean;
	notDebitedToContractorReason?: string;
	contractorInformed?: boolean;
	contractorInformedFiles?: string;
	contractorNotInformedReason?: string;
	debitWorkOrderId?: number;
	debitRecover?: boolean;
	nextApprovedBy?: number;
	createdAt?: string;
	updatedAt?: string;
	createdBy?: number;
	updatedBy?: number;

	site?: Site;
	workOrder?: WorkOrder;
	projectProcessMaster?: ProjectProcessMaster;
	contractor?: User;
	createdUser?: User;
	updatedUser?: User;
	nextApprovedUser?: User;
	paymentMilestones: PaymentMilestone[];
	workOrderDebits?: WorkOrderDebit[];
	isAdvanceAmount?: boolean;
	debitWorkOrder?: WorkOrder;
	advancePaymentMilestones: PaymentMilestone[];

	key?: string;
};

export enum WorkOrderBillStatus {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	PAID = 'PAID',
	CANCELLED = 'CANCELLED',
}

export const billStatusLabels: Record<string, string> = {
	[WorkOrderBillStatus.PENDING]: 'Pending',
	[WorkOrderBillStatus.APPROVED]: 'Approved',
	[WorkOrderBillStatus.PAID]: 'Paid',
	[WorkOrderBillStatus.CANCELLED]: 'Cancelled',
};

export enum WorkOrderBillType {
	WORK_ORDER = 'WORK_ORDER',
	EXTRA_AMOUNT = 'EXTRA_AMOUNT',
	ADVANCE_AMOUNT = 'ADVANCE_AMOUNT',
	PLASTER_DEBIT = 'PLASTER_DEBIT',
	MALWA_BILL = 'MALWA_BILL',
	SCAFFOLDING_BILL = 'SCAFFOLDING_BILL',
	DEBIT_TO_CONTRACTOR = 'DEBIT_TO_CONTRACTOR',
	CHALLAN_BILL_CONTRACTOR_DEBIT = 'CHALLAN_BILL_CONTRACTOR_DEBIT',
	WARRANTY_BILL = 'WARRANTY_BILL',
	WATER_TANKER_BILL = 'WATER_TANKER_BILL',
}

export const billTypeLabels: Record<string, string> = {
	[WorkOrderBillType.WORK_ORDER]: 'Work Order Bill',
	[WorkOrderBillType.EXTRA_AMOUNT]: 'Extra Bill',
	[WorkOrderBillType.ADVANCE_AMOUNT]: 'Advance Amount Bill',
	[WorkOrderBillType.PLASTER_DEBIT]: 'Plaster Debit Bill',
	[WorkOrderBillType.MALWA_BILL]: 'Malwa Bill',
	[WorkOrderBillType.SCAFFOLDING_BILL]: 'Scaffolding Bill',
	[WorkOrderBillType.DEBIT_TO_CONTRACTOR]: 'Extra Bill - Debit to contractor',
	[WorkOrderBillType.CHALLAN_BILL_CONTRACTOR_DEBIT]: 'Challan Bill - Debit to contractor',
	[WorkOrderBillType.WARRANTY_BILL]: 'Warranty Bill',
	[WorkOrderBillType.WATER_TANKER_BILL]: 'Water Tanker Bill',
};

export const billTypeWorkType: Record<string, string> = {
	[WorkOrderBillType.MALWA_BILL]: 'Malwa Removal',
	[WorkOrderBillType.SCAFFOLDING_BILL]: 'Folding Supply',
};
