import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {
	checkFileErr,
	equalNum,
	getCurrencyString,
	parseNum,
	toTitleCase,
} from 'app/helpers';
import type Types from 'MyTypes';
import {Button, Col, Empty, Form, Input, message, Radio, Row, Select, Spin, Card, Table} from 'antd';
import {log} from 'app/services/logger-service';
import {type UploadFile} from 'app/models/upload-file';
import {create, getUserWallet} from './actions';
import {get as getWorkOrders} from '../WorkOrder/actions';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {FileUpload} from '../Common/FileUpload';
import {ExpenseType, type Site, WorkOrderStatus} from 'app/models';

const {TextArea} = Input;
const {Option} = Select;

type OwnProps = {
	sites: Site[];
};

const mapStateToProps = ({
	expenseReport,
	workOrder: {byIds: byIdsWo, allIds: allIdsWo},
	summary: {user: authUser},
}: Types.RootState, ownProps: OwnProps) => ({
	...expenseReport,
	byIdsWo,
	allIdsWo,
	authUser,
	...ownProps,
});

const mapDispatchToProps = {
	create,
	getUserWallet,
	getWorkOrders,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

type State = {
	siteId?: number;
	expenseType: string;
	expenseDescription: string;
	expenseAmount: number;
	debitedToContractor?: boolean;
	notDebitedToContractorReason?: string;
	contractorInformed?: boolean;
	contractorInformedFiles?: string;
	contractorNotInformedReason?: string;
	debitWorkOrderId?: number;
	fileList: UploadFile[];
	filePrefixKeys: Record<string, string>;
	informedFileList: UploadFile[];
	informedFilePrefixKeys: Record<string, string>;
};

type DataType = {
	siteId?: number;
	expenseType: string;
	expenseDescription: string;
	expenseAmount: number;
	debitedToContractor?: boolean;
	notDebitedToContractorReason?: string;
	contractorInformed?: boolean;
	contractorInformedFiles?: string;
	contractorNotInformedReason?: string;
	debitWorkOrderId?: number;
	fileList: UploadFile[];
	filePrefixKeys: Record<string, string>;
	informedFileList?: UploadFile[];
	informedFilePrefixKeys?: Record<string, string>;
	walletId?: number;
};

class ExpenseReportForm extends React.Component<Props, State> {
	typeOptions: FilterOptionItem[];
	dataSource: any[];

	constructor(props: Props) {
		super(props);

		let siteId = 0;
		if (props.sites && props.sites.length === 1) {
			siteId = props.sites[0].id;
		}

		this.state = {
			siteId,
			expenseType: '',
			expenseDescription: '',
			expenseAmount: 0,
			debitedToContractor: undefined,
			notDebitedToContractorReason: undefined,
			contractorInformed: undefined,
			contractorInformedFiles: undefined,
			contractorNotInformedReason: undefined,
			debitWorkOrderId: undefined,
			fileList: [],
			filePrefixKeys: {},
			informedFileList: [],
			informedFilePrefixKeys: {},
		};

		this.typeOptions = Object.keys(ExpenseType).map(type =>
			({value: type, label: toTitleCase(type, '_') ?? ''}));

		this.dataSource = [
			{
				expenseHead: 'Food Beverage Expense',
				expenseDescription: 'Expenses related to food and/or beverages purchased during client and Prithu Officials visit.',
			},
			{
				expenseHead: 'Site Loading Unloading Expense',
				expenseDescription: 'Expense related to transportation for Material Shifting from site to site, Material Loading Unloading',
			},
			{
				expenseHead: 'Site Material Expense',
				expenseDescription: 'Expense related to the material which is marked as "BUY LOCALLY" by the procurement team',
			},
			{
				expenseHead: 'Site Stationary Expense',
				expenseDescription: 'Expense related to all the stationary items i.e. Drawing Print outs etc.',
			},
			{
				expenseHead: 'Conveyance',
				expenseDescription: 'Expense related to the Site related visits ',
			},
			{
				expenseHead: 'Labour Expense : Debit to Contractor',
				expenseDescription: 'Expense related to Chowk Labour which is marked as "Complete on site level" by QS team',
			},
			{
				expenseHead: 'Labour Expense : Not Debit to Contractor',
				expenseDescription: 'Expense related to Chowk Labour which is marked as "Complete on site level" by QS team',
			},
			{
				expenseHead: 'Water and Electricity Bill',
				expenseDescription: 'Expenses related to water and electricity. This has to be booked by the liasoning team',
			},
			{
				expenseHead: 'Liasoning Expense',
				expenseDescription: 'Expenses related to the site liasoning expenses i.e surprise visits of govt.officials etc.',
			},
			{
				expenseHead: 'Other Expense',
				expenseDescription: 'Miscellaneous expenses which are not mentioned in defined petty cash expense heads',
			},
			{
				expenseHead: 'Malwa Expense',
				expenseDescription: 'Expense related to Malwa shifting from site after pre-approval of QS team',
			},
		];
	}

	componentDidMount() {
		log('ExpenseReportForm.componentDidMount');
		this.props.getUserWallet(this.props.authUser?.id ?? 0);
		const siteIds = this.props.sites.map(({id}) => id);
		this.props.getWorkOrders({
			where: {siteId: {inq: siteIds}},
		});
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			void message.success('Added successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}

		if (nextProps.errorMessage) {
			void message.error(nextProps.errorMessage);
		}
	}

	// eslint-disable-next-line complexity
	onSubmitClicked = () => {
		const {userWallet} = this.props;

		const data: DataType = {...this.state};

		if (!data.siteId) {
			void message.error('Please select a site');
			return;
		}

		if (!userWallet?.id) {
			void message.error('No wallet found');
			return;
		}

		if (!data.expenseAmount) {
			void message.error('Please enter expenses amount');
			return;
		}

		if (data.expenseType === String(ExpenseType.LABOUR_EXPENSE) && data.debitedToContractor === undefined) {
			void message.error('Please select if the expense amount will be debited to any contractor or not!');
			return;
		}

		if (data.expenseType === String(ExpenseType.LABOUR_EXPENSE)
			&& !data.debitedToContractor && !data.notDebitedToContractorReason) {
			void message.error('Please enter a reason for not debiting to contractor!');
			return;
		}

		if (data.expenseType === String(ExpenseType.LABOUR_EXPENSE) && data.debitedToContractor && !data.debitWorkOrderId) {
			void message.error('Please select a work order to debit from!');
			return;
		}

		if (data.expenseType === String(ExpenseType.LABOUR_EXPENSE)
			&& data.debitedToContractor && data.contractorInformed === undefined) {
			void message.error('Please select if the contractor has been informed for this debit!');
			return;
		}

		if (data.expenseType === String(ExpenseType.LABOUR_EXPENSE)
			&& data.contractorInformed && (!data.informedFileList?.length)) {
			void message.error('Please upload screenshots of the communication!');
			return;
		}

		if (data.expenseType === String(ExpenseType.LABOUR_EXPENSE)
			&& data.debitedToContractor && !data.contractorInformed && !data.contractorNotInformedReason) {
			void message.error('Please provide a reason for no communication!');
			return;
		}

		if (data.expenseType === String(ExpenseType.LAISIONING_EXPENSE) && !data.expenseDescription) {
			void message.error('Please write the name of person and his mobile number to whom payment has been made in the description.');
			return;
		}

		if (data.expenseType === String(ExpenseType.LAISIONING_EXPENSE) && !data.fileList?.length) {
			void message.error('Please upload supporting file for this head.');
			return;
		}

		checkFileErr(data.fileList);
		if (data.informedFileList) {
			checkFileErr(data.informedFileList);
		}

		data.walletId = userWallet.id;
		data.siteId = parseNum(data.siteId);
		data.expenseDescription = data.expenseDescription.split(',').join('-');
		data.expenseAmount = Math.round(data.expenseAmount);

		if (data.expenseType === String(ExpenseType.SCRAP_AMOUNT)) {
			// If the expense type is scrap amount it will added as negative amount
			data.expenseAmount *= -1;
		}

		if (data.informedFileList && data.informedFilePrefixKeys) {
			data.contractorInformedFiles = data.informedFileList
				.map((file: UploadFile) => `${data.informedFilePrefixKeys?.[file.uid]}/${file.name}`).join(',');
		}

		delete data.informedFileList;
		delete data.informedFilePrefixKeys;

		this.props.create(data);
	};

	render() {
		const {allIdsWo, byIdsWo, userWallet, loading} = this.props;
		const {siteId, expenseType, expenseDescription, expenseAmount, fileList, filePrefixKeys,
			informedFileList, informedFilePrefixKeys, debitedToContractor, contractorInformed,
			contractorNotInformedReason, notDebitedToContractorReason} = this.state;

		const orderOptions: FilterOptionItem[] = allIdsWo
			.filter((id: number) => (!siteId || equalNum(byIdsWo[id].siteId, siteId))
				&& byIdsWo[id].status === WorkOrderStatus.PUBLISHED)
			.map((id: number) => ({value: id.toString(), label: byIdsWo[id].title}));
		if (!userWallet) {
			return <Empty description={'No wallet found. Please contact Accounts Team to get your wallet added.'} />;
		}

		const layout = {
			labelCol: {span: 8},
			wrapperCol: {span: 16},
		};
		const tailLayout = {
			wrapperCol: {offset: 8, span: 16},
		};

		return (
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Form
							{...layout}
							name='basic'
						>
							<Form.Item label='Current Limit'>
								{getCurrencyString(userWallet.limit, false)}
								{' '}
								(Please raise a new query for increasing or decreasing limit.)
							</Form.Item>
							<Form.Item label='Current Balance'>
								{getCurrencyString(userWallet.balance)}
							</Form.Item>
							<Form.Item label='Serial No.'>
								{userWallet.nextSno ? userWallet.nextSno : 1}
							</Form.Item>

							{(!siteId || this.props.sites.length > 1) && (
								<Form.Item label='Site'>
									<Select
										showSearch={true}
										style={{width: '100%'}}
										placeholder='Select a Site'
										optionFilterProp='children'
										onChange={value => {
											this.setState({siteId: value as number});
										}}
									>
										{this.props.sites.map((site: any, ix: number) =>
											<Option key={ix} value={String(site.id)}>{site.name}</Option>,
										)}
									</Select>
								</Form.Item>
							)}

							<Form.Item label='Expense Type'>
								<Select
									showSearch={true}
									id={'SelectExpenseType'}
									style={{width: '100%'}}
									placeholder='Select Expense Type'
									optionFilterProp='children'
									onChange={value => {
										this.setState({
											expenseType: String(value),
											debitWorkOrderId: undefined,
											debitedToContractor: undefined,
											notDebitedToContractorReason: undefined,
											contractorInformed: undefined,
											informedFileList: [],
											informedFilePrefixKeys: {},
											contractorInformedFiles: undefined,
											contractorNotInformedReason: undefined,
										});
									}}
								>
									{this.typeOptions.map((role: FilterOptionItem, ix: number) =>
										<Option key={ix} value={role.value}>{role.label}</Option>,
									)}
								</Select>
							</Form.Item>

							<Form.Item label='Expense Amount'>
								<Input
									prefix={expenseType === String(ExpenseType.SCRAP_AMOUNT) ? '-' : '+'}
									type={'number'}
									id={'EnterExpenseAmount'}
									disabled={!expenseType}
									placeholder={'Enter Expense Amount'}
									value={expenseAmount}
									onChange={(e: any) => {
										this.setState({expenseAmount: parseNum(String(e.target.value))});
									}}
								/>
								<small>Amount entered in decimal will be round off./दशमलव में दर्ज राशि राउंड ऑफ होगी।</small>
							</Form.Item>

							<Form.Item label='Expense Description'>
								<TextArea
									id={'EnterExpenseDescription'}
									placeholder={'Enter Expense Description'}
									value={expenseDescription ?? ''}
									onChange={(e: any) => {
										this.setState({expenseDescription: String(e.target.value)});
									}
									}
								/>
								{this.state.expenseType === String(ExpenseType.LAISIONING_EXPENSE) ? (
									<small>{'(Required) Write the name of person and his mobile number to whom payment has been made.'}</small>
								) : []}
							</Form.Item>

							<Form.Item label='Attachments'>
								<FileUpload
									prefix={'expense-report'}
									fileList={fileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={((list, prefixKeys) => {
										this.setState({
											fileList: list,
											filePrefixKeys: prefixKeys,
										});
									})}
								/>
								{this.state.expenseType === String(ExpenseType.LAISIONING_EXPENSE) ? (
									<small>{'(Required) Please upload the voucher for labour expense for the amount paid.'}</small>
								) : []}
							</Form.Item>

							<Form.Item label='Debit this expense to a contractor?'>
								<Radio.Group
									onChange={e => {
										this.setState({
											debitedToContractor: Boolean(e.target.value),
											debitWorkOrderId: undefined,
											notDebitedToContractorReason: undefined,
											contractorInformed: undefined,
											informedFileList: [],
											informedFilePrefixKeys: {},
											contractorInformedFiles: undefined,
											contractorNotInformedReason: undefined,
										});
									}}
								>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>

							{debitedToContractor === undefined ? [] : (
								debitedToContractor ? (
									<>
										<Form.Item label={'Has contractor been informed for this debit?'}>
											<Radio.Group
												onChange={e => {
													this.setState({
														contractorInformed: Boolean(e.target.value),
														informedFileList: [],
														informedFilePrefixKeys: {},
														contractorInformedFiles: undefined,
														contractorNotInformedReason: undefined,
													});
												}}
											>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
										{contractorInformed === undefined ? [] : (
											contractorInformed ? (
												<Form.Item
													label='Upload screenshots of the communication'
													extra={'Upload Whatsapp message screenshot or email screenshot of the conversation with contractor'}
												>
													<FileUpload
														prefix={'labour-request'}
														fileList={informedFileList}
														filePrefixKeys={informedFilePrefixKeys}
														onFileChange={((list, prefixKeys) => {
															this.setState({
																informedFileList: list,
																informedFilePrefixKeys: prefixKeys,
															});
														})}
													/>
												</Form.Item>
											) : (
												<Form.Item label='Please Enter a reason'>
													<Input.TextArea
														placeholder={'Enter a Reason'}
														value={contractorNotInformedReason ?? ''}
														onChange={(e: any) => {
															this.setState({contractorNotInformedReason: String(e.target.value)});
														}}
													/>
												</Form.Item>
											)
										)}
										<Form.Item label='Work Order of Contractor'>
											<Select
												showSearch={true}
												style={{width: 250}}
												placeholder='Select a Work Order'
												optionFilterProp='children'
												onChange={(value: string) => {
													this.setState({debitWorkOrderId: parseNum(value)});
												}}
											>
												{orderOptions.map((option: FilterOptionItem, ix: number) =>
													<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
												)}
											</Select>
										</Form.Item>
									</>
								) : (
									<Form.Item label='Please Enter a reason'>
										<Input.TextArea
											placeholder={'Enter a Reason'}
											value={notDebitedToContractorReason ?? ''}
											onChange={(e: any) => {
												this.setState({notDebitedToContractorReason: String(e.target.value)});
											}}
										/>
									</Form.Item>
								)
							)}

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={this.onSubmitClicked} id={'Submit'}>Submit</Button>
							</Form.Item>
						</Form>
					</Col>
					<Col span={24}>
						<Card size='default' title={<b>HOW TO CLASSIFY THE EXPENSES RIGHT?</b>}>
							<Table
								dataSource={this.dataSource}
								pagination={false}
							>
								<Table.Column
									title={<b>Petty Cash Expense Head</b>}
									width={400}
									dataIndex='expenseHead'
									key='expenseHead'
								/>
								<Table.Column
									title={<b>Expense Description</b>}
									width={400}
									dataIndex='expenseDescription'
									key='expenseDescription'
								/>
							</Table>
						</Card>
					</Col>
				</Row>
			</Spin>
		);
	}
}

export default connector(ExpenseReportForm);
