import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Col, Menu, Row, Typography} from 'antd';
import {uiPaths} from 'app/constants';
import {ViewAllWorkOrderSchedule} from '../WorkOrderSchedule/ViewAllWorkOrderSchedule';
import * as qs from 'qs';
import {hasPermission} from 'app/helpers';
import {workOrderPageType} from './constants';
import {ViewAllWorkOrder} from 'app/components/WorkOrder/ViewAllWorkOrder';
import {changePage} from './actions';
import {ModuleName, ModulePermission} from 'app/models';
import {useLocation} from 'react-router-dom';

export const ViewWorkOrder: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const {pageType} = useSelector((state: Types.RootState) => state.workOrder);
	const {byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);

	const q: any = qs.parse(location.search, {ignoreQueryPrefix: true});
	const canApprove: boolean = permissions ? hasPermission(permissions[ModuleName.WORK_ORDER], ModulePermission.APPROVE) : false;
	const canViewReport: boolean = permissions ? hasPermission(permissions[ModuleName.WORK_ORDER_REPORT], ModulePermission.READ) : false;

	const page = q.page as string;

	React.useEffect(() => {
		if (page) {
			dispatch(changePage(page));
		}
	}, [page]);

	const currentPageType: string | undefined = pageType ?? workOrderPageType.default;

	return (
		<DefaultLayout currentPath={uiPaths.workOrder}>
			<Row>
				<Col span={24} style={{textAlign: 'center'}}>
					<Typography.Title level={3}>Work Order</Typography.Title>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(changePage(e.key))}
						selectedKeys={[currentPageType]}
						mode='horizontal'
					>
						<Menu.Item id={workOrderPageType.default} key={workOrderPageType.default}>
							Work Order
						</Menu.Item>
						<Menu.Item id={workOrderPageType.all} key={workOrderPageType.all}>
							All Work Order
						</Menu.Item>
					</Menu>
				</Col>
			</Row>
			<br /><br />

			{currentPageType === workOrderPageType.default && (
				<ViewAllWorkOrderSchedule />
			)}
			{currentPageType === workOrderPageType.all && (
				<ViewAllWorkOrder />
			)}
		</DefaultLayout>
	);
};
