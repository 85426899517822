import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {type User, SiteStatus, type WarrantyQueryReport} from 'app/models';
import {get as getSites} from '../Site/actions';
import {type DataSourceType, ViewHierarchyReport, createUserTree, emptyNode, populateDataSourceTree} from './ViewHierarchyReport';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {formatDateFunction, parseNum} from 'app/helpers';
import {getWarrantyQueryReport} from './warranty-query-report-reducer';
import {uiPaths} from 'app/constants';
import {getKey, mutateTree} from 'app/services/report-service';

export const ViewWarrantyQueryReport: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.warrantyQueryReport,
	);
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {sites: allSites} = useSelector((state: Types.RootState) => state.site);

	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, Record<number, WarrantyQueryReport>>>(
		(record, item) => {
			const date = formatDateFunction(item.reportDate, false);
			if (!record[date]) {
				record[date] = {};
			}

			record[date][item.siteId] = item;
			return record;
		},
		{},
	);

	const reportColumns = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.map(date => ({
			title: date,
			key: getKey(date),
			width: 240,
			children: [
				{
					title: 'Open',
					key: `${getKey(date)}-open`,
					width: 80,
				},
				{
					title: 'Closed',
					key: `${getKey(date)}-closed`,
					width: 80,
				},
				{
					title: 'Raised',
					key: `${getKey(date)}-raised`,
					width: 80,
				},
			],
		}));

	const createDataSourceTree = (node: DataSourceType) => {
		const nodeKeys = node.key.split('-');
		if (nodeKeys[0] === 'user') {
			const userId = parseNum(nodeKeys[1]);
			const siteChildren = allSites
				.filter(site => site.siteInchargeId === userId && site.status === SiteStatus.UNDER_WARRANTY)
				.map(site => {
					const records: Record<string, number> = {};

					Object.keys(mapOfRecords).forEach(date => {
						const report = mapOfRecords[date]?.[site.id];
						records[`${getKey(date)}-open`] = report?.open ?? 0;
						records[`${getKey(date)}-closed`] = report?.closed ?? 0;
						records[`${getKey(date)}-raised`] = report?.raised ?? 0;
					});

					return {
						key: 'site-' + String(site.id),
						name: site?.name ?? '',
						records,
					};
				});

			if (siteChildren?.length) {
				if (node.children?.length) {
					node.children.push(...siteChildren);
				} else {
					node.children = siteChildren;
				}
			}

			if (node.children?.length) {
				node.children.forEach(childNode => createDataSourceTree(childNode));
			}
		}

		return node;
	};

	React.useEffect(() => {
		dispatch(
			getWarrantyQueryReport({
				where: {
					and: getQueryDateRangeForDays(
						new Date().toDateString(),
						0,
						36,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(
			getSites({
				where: {
					status: SiteStatus.UNDER_WARRANTY,
				},
			}),
		);
	}, []);

	const roots: User[] = mutateTree(
		users,
		allSites
			.filter(site => site.status === SiteStatus.UNDER_WARRANTY)
			.map(({siteInchargeId}) => siteInchargeId ?? 0),
	);

	const userTree = roots.map(user => createUserTree({...emptyNode}, user));
	const initDataSource = userTree.map(userNode => createDataSourceTree(userNode));
	const dataSource = initDataSource.map(node => populateDataSourceTree(node, false));

	return (
		<DefaultLayout currentPath={uiPaths.weeklyWarrantyQueryReport}>
			<ViewHierarchyReport
				title='Warranty Query Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
			/>
		</DefaultLayout>
	);
};
