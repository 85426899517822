import React from 'react';
import {Button, Col, Row, Space} from 'antd';
import {UserType, type User} from 'app/models';
import {uiPaths} from 'app/constants';
import {Link} from 'react-router-dom';
import {toTitleCase} from 'app/helpers';
import {UserRole} from 'app/models/enums/user-role.enum';

type Props = {
	user?: User;
	canEdit?: boolean;
};

export const ViewUserInfo: React.FC<Props> = ({user, canEdit}) => (
	<Row>
		<Col span={24}>
			<p><b>Email: </b> {user?.email ?? ''}</p>
			<p><b>Role: </b> {toTitleCase(user?.roles ?? '', '_')}</p>
			<p><b>Mobile Number: </b> {user?.mobileNumbers ?? ''}</p>
			<p><b>Pan Card: </b> {user?.panNumber ?? ''}</p>
			{user?.userType === UserType.VENDOR ? (
				<>
					<p><b>GST Number: </b> {user?.gstNumber ?? ''}</p>
					<p><b>Contact Person: </b> {user?.contactPerson ?? ''}</p>
				</>
			) : []}
			{(user?.roles !== UserRole.CLIENT && user?.roles !== UserRole.VENDOR) ? (
				<>
					<p>Blood Group: {user?.userDetail?.bloodGroup ?? ''}</p>
					<p>Local Address: <a href={user?.userDetail?.localAddress?.googlePin} target={'_blank'} rel='noreferrer'>{user?.userDetail?.localAddress?.addressLine ?? ''}</a></p>
					<p>Permanent Address: <a href={user?.userDetail?.permanentAddress?.googlePin} target={'_blank'} rel='noreferrer'>{user?.userDetail?.permanentAddress?.addressLine ?? ''}</a></p>
					<p>Emergency Contact: {user?.userDetail?.emergencyContact?.name ?? ''} {user?.userDetail?.emergencyContact?.phone ?? ''}</p>
				</>
			) : []}
			{user?.paymentDetail ? (
				<>
					<p><label>GST Number:</label> {user?.paymentDetail?.gstNumber}</p>
					<p><label>Bank A/C Number:</label> {user?.paymentDetail?.bankACNumber}</p>
					<p><label>Bank A/C Name:</label> {user?.paymentDetail?.bankACHolderName}</p>
					<p><label>Bank Name:</label> {user?.paymentDetail?.bankName}</p>
					<p><label>IFSC Code:</label> {user?.paymentDetail?.ifscCode}</p>
				</>
			) : []}
			<br/>
			<br/>
			{canEdit ? (
				<Space>
					<Link to={{pathname: '/' + uiPaths.editProfile}}>
						<Button type='primary'>Edit Profile</Button>
					</Link>
					<Link to={{pathname: '/' + uiPaths.changePassword}}>
						<Button type='primary'>Change Password</Button>
					</Link>
				</Space>
			) : []}
		</Col>
	</Row>
);
