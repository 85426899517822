import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Empty,
	message,
	Row,
	Space,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
} from './actions';
import {Link} from 'react-router-dom';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';
import {type MaterialMaster} from './material-master';
import {isMobile} from 'app/helpers';

export const ViewAllMaterialMaster: React.FC = () => {
	const dispatch = useDispatch();
	const {
		loading,
		byIds,
		allIds,
		filterValue,
		dataUpdated,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
	} = useSelector((state: Types.RootState) => state.materialMaster);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f.name) {
			filter.where.name = {like: `%25${f.name as string}%25`};
		}

		if (f.parentId) {
			filter.where.parentId = Number(f.parentId);
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const dataSource: MaterialMaster[] = allIds?.length
		? allIds.map(id => byIds[id])
		: [];

	// Get parent items for filter dropdown
	const parentOptions: FilterOptionItem[] = dataSource
		.filter(item => !item.parentId)
		.map(item => ({
			label: item.name,
			value: String(item.id),
		}));

	const uiFilters = [
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Name',
			label: 'Name',
			defaultValue: filterValue ? filterValue.name : '',
		},
		{
			filterKey: 'parentId',
			formType: FilterFormType.SELECT,
			placeholder: 'Select Parent',
			label: 'Parent',
			defaultValue: filterValue ? filterValue.parentId : undefined,
			options: parentOptions,
		},
	];

	const getParentName = (record: MaterialMaster) => {
		if (!record.parentId || record.isCostHead) { // Don't show parent if it's a cost head
			return '';
		}

		const parent = byIds[record.parentId];
		return parent ? parent.name : '';
	};

	const getCostHeadNames = (record: MaterialMaster) => {
		if (!record.costHeads || record.costHeads.length === 0) {
			return '-';
		}

		return record.costHeads.map(head => head.name).join(', ');
	};

	return (
		<DefaultLayout currentPath={uiPaths.allMaterialMasters}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Material Master
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Link
							to={{
								pathname: `/${uiPaths.addMaterialMaster}`,
							}}
						>
							<Button type='primary'>Add New Material</Button>
						</Link>
						<br />
						<br />
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
							>
								<Table.Column
									width={300}
									title='Name'
									dataIndex='name'
									key='name'
								/>
								<Table.Column
									width={100}
									title='Is Cost Head'
									key='isCostHead'
									render={(record: MaterialMaster) => (
										record.isCostHead ? 'Yes' : 'No'
									)}
								/>
								<Table.Column
									title='Parent'
									key='parent'
									render={(record: MaterialMaster) => getParentName(record)}
								/>
								<Table.Column
									width={300}
									title='Cost Heads'
									key='costHeads'
									render={(record: MaterialMaster) => getCostHeadNames(record)}
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(action: string, item: MaterialMaster) => (
										<Space direction={isMobile ? 'vertical' : 'horizontal'}>
											<Link
												to={{
													pathname: `/${uiPaths.editMaterialMaster.replace(
														':id',
														String(item.id),
													)}`,
												}}
											>
												<Button size={'small'} type='primary'>
													Edit
												</Button>
											</Link>
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
