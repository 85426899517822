
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	changeAllPage,
	count,
	get,
	onPaginationChange,
	setFilterValue,
} from './actions';
import {Col, Empty, Row, Space, Spin, Tag, Tooltip} from 'antd';
import {
	formatDateFunction,
	getPermissionSites,
	parseNum,
	toSubstring,
} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {
	type MaterialQuery,
	MaterialQueryStatus,
	materialQueryStatusLabels,
	ModuleName,
	ModulePermission,
	type Site,
	type User,
} from 'app/models';
import {getQueryDateRange} from 'app/query_helpers';
import {Table} from 'antd';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {CheckCircleOutlined} from '@ant-design/icons';
import {UserRole} from 'app/models/enums/user-role.enum';
import {useHistory} from 'react-router';
import type Types from 'MyTypes';
import {get as getCategories} from '../Category/actions';
import {CategoryType, type Category} from '../Category/category';

type Props = {
	pageType?: string;
	toBeApproved?: boolean;
};

export const ViewAllMaterialQuery: React.FC<Props> = ({
	pageType,
	toBeApproved,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		loading,
		byIds,
		allIds,
		totalCount,
		currentPos,
		perPageSize,
		filterValue,
		allPageType,
	} = useSelector((state: Types.RootState) => state.materialQuery);
	const {subordinateOptions: userOptions, user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {byIds: categoryByIds, allIds: allCategoryIds} = useSelector((state: Types.RootState) => state.category);

	const userStake = authUser?.roles;

	const sites: Site[] = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_QUERY,
		ModulePermission.READ,
		allSites,
	);
	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));
	const statusOptions: FilterOptionItem[] = Object.values(MaterialQueryStatus)
		.map(status => ({
			value: status,
			label: materialQueryStatusLabels[status],
		}));

	const categories = allCategoryIds.map(id => categoryByIds[id]).filter(category => !category.isDeactivated);

	const categoryOptions = categories
		.filter(category => !category.parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const subCategoryOptions = (parentId: number) => categories
		.filter(category => parentId && category.parentId === parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const today = new Date();

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
		};

		const orQuery: any[] = [];

		if (toBeApproved) {
			filter.where.nextApprovedBy = authUser?.id;
		} else {
			orQuery.push({
				siteId: {inq: sites.map(({id}) => id)},
			});
			orQuery.push({
				createdBy: {
					inq: userOptions
						.filter(({value}: FilterOptionItem) => value)
						.map(({value}: FilterOptionItem) => parseNum(value)),
				},
			});
		}

		if (orQuery.length) {
			filter.where.and = [{or: orQuery}];
		} else {
			filter.where.and = [];
		}

		if (f.id) {
			filter.where.id = parseNum(String(f.id));
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.date1 && f.date2) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(
				...getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt'),
			);
		}

		if (f.requirementDate1 && f.requirementDate2) {
			filter.where.and = getQueryDateRange(f.requirementDate1 as string, f.requirementDate2 as string, 'requirementDate');
		}

		if (f.leadRequirementDate1 && f.leadRequirementDate2) {
			filter.where.and = getQueryDateRange(f.leadRequirementDate1 as string, f.leadRequirementDate2 as string, 'leadRequirementDate');
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		if (f.debitedToContractor) {
			filter.where.debitedToContractor = f.debitedToContractor;
		}

		if (f.debitNotApproved) {
			filter.where.debitApproved = false;
			filter.where.debitedToContractor = true;
		}

		if (f.categoryId) {
			filter.where.categoryId = parseNum(f.categoryId as string);
		}

		if (f.subCategoryId) {
			filter.where.subCategoryId = parseNum(f.subCategoryId as string);
		}

		dispatch(setFilterValue(f));
		console.log(filter);
		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);

		dispatch(getCategories({
			where: {
				categoryType: CategoryType.MATERIAL_QUERY,
			},
		}));

		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}

		if (pageType !== allPageType) {
			if (pageType) {
				dispatch(changeAllPage(pageType));
			}
		}
	}, []);

	const queries: MaterialQuery[] = allIds.map((id: number) => byIds[id]);
	const uiFilters: UiFilter[] = [
		{
			filterKey: 'id',
			formType: FilterFormType.TEXT,
			label: 'Id',
			defaultValue: filterValue?.id,
		},
		{
			filterKey: 'date',
			items: [],
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
		{
			filterKey: 'requirementDate',
			formType: FilterFormType.DATE_RANGE,
			label: 'Requirement Date (As per site)',
			defaultValue: [filterValue?.requirementDate1 as string, filterValue?.requirementDate2 as string],
		},
		{
			filterKey: 'leadRequirementDate',
			formType: FilterFormType.DATE_RANGE,
			label: 'Requirement Date (As per lead time)',
			defaultValue: [filterValue?.leadRequirementDate1 as string, filterValue?.leadRequirementDate2 as string],
		},
		{
			filterKey: 'categoryId',
			items: categoryOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Category',
			label: 'Category',
			defaultValue: filterValue?.categoryId,
		},
		{
			filterKey: 'subCategoryId',
			items: subCategoryOptions(parseNum(String(filterValue?.categoryId))),
			formType: FilterFormType.SELECT,
			placeholder: 'Select Sub Category',
			label: 'Sub Category',
			defaultValue: filterValue?.subCategoryId,
		},
	];

	if (siteOptions.length > 1) {
		uiFilters.push({
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			formWidth: 240,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		});
	}

	if (!toBeApproved) {
		uiFilters.push({
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: filterValue?.status,
		});
	}

	if (userStake === UserRole.CONTRACT_DEVELOPMENT_AND_RELATIONSHIP_MANAGER) {
		uiFilters.push({
			filterKey: 'debitedToContractor',
			formType: FilterFormType.BOOLEAN,
			label: 'Debited to Contractor',
			defaultValue: filterValue.debitedToContractor,
		});
	}

	if (userStake === UserRole.PROCUREMENT_INCHARGE) {
		uiFilters.push({
			filterKey: 'debitNotApproved',
			formType: FilterFormType.BOOLEAN,
			label: 'Debit Not Approved',
			defaultValue: filterValue.debitNotApproved,
		});
	}

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						onSubmit={(f: FilterDataType) => {
							filterData(f);
						}}
						submitOnChange={true}
					/>
					<br />
				</Col>
			</Row>

			{totalCount ? (
				<Row>
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={currentPos ?? 1}
							perPageSize={perPageSize ?? 10}
							filterValue={filterValue}
							filterData={filterData}
							onPaginationChange={onPaginationChange}
						/>
						<Table
							size={'small'}
							dataSource={queries}
							pagination={false}
							scroll={{x: 500, y: 600}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.materialQueryDetail.replace(
											':queryId',
											String(query.id),
										)}`,
									});
								},
							})}
						>
							<Table.Column
								title='Id'
								dataIndex='id'
								key='id'
							/>
							<Table.Column
								title='Raised At'
								dataIndex='createdAt'
								key='createdAt'
								render={(createdAt: string | undefined) =>
									formatDateFunction(createdAt ?? '')
								}
							/>
							<Table.Column
								title='Approved At'
								dataIndex='approvedAt'
								key='approvedAt'
								render={(approvedAt: string | undefined) =>
									formatDateFunction(approvedAt ?? '')
								}
							/>
							<Table.Column
								title='Site'
								dataIndex='site'
								key='site'
								render={(site: Site | undefined) => site?.name ?? ''}
							/>
							<Table.Column
								title='Query Point'
								dataIndex='description'
								key='description'
								render={(description: string) => toSubstring(description, 80, '...')}
							/>
							<Table.Column
								title='Debited to Contractor'
								dataIndex='debitedToContractor'
								key='debitedToContractor'
								render={(val: boolean, query: MaterialQuery) =>
									val ? (
										<Space>
                      Yes
											{query.debitApproved ? (
												<Tooltip title='This debit is approved.'>
													<CheckCircleOutlined />
												</Tooltip>
											) : (
												[]
											)}
										</Space>
									) : (
										'No'
									)
								}
							/>
							<Table.Column
								title='Status'
								dataIndex='status'
								key='status'
								render={(status: string) =>
									status ? materialQueryStatusLabels[status] : ''
								}
							/>
							<Table.Column
								title='Category'
								dataIndex='category'
								key='category'
								render={(category: Category | undefined) =>
									category?.name ?? ''
								}
							/>
							<Table.Column
								title='Sub Category'
								dataIndex='subCategory'
								key='subCategory'
								render={(subCategory: Category | undefined) =>
									subCategory?.name ?? ''
								}
							/>
							<Table.Column
								title='Requirement Date (As per lead time)'
								dataIndex='leadRequirementDate'
								key='leadRequirementDate'
								render={(leadRequirementDate: string | undefined) => {
									if (!leadRequirementDate) {
										return '';
									}

									return (
										<Tag color={new Date(leadRequirementDate) < today ? 'red' : ''}>
											<Space>
												{formatDateFunction(leadRequirementDate, false)}
											</Space>
										</Tag>
									);
								}}
							/>
							<Table.Column
								title='Requirement Date (As per site)'
								dataIndex='requirementDate'
								key='requirementDate'
								render={(requirementDate: string | undefined) => {
									if (!requirementDate) {
										return '';
									}

									return (
										<Tag color={new Date(requirementDate) < today ? 'red' : ''}>
											<Space>
												{formatDateFunction(requirementDate, false)}
											</Space>
										</Tag>
									);
								}}
							/>
							<Table.Column
								title='Raise By'
								dataIndex='createdUser'
								key='createdUser'
								render={(createdUser: User | undefined) =>
									createdUser?.name ?? ''
								}
							/>
							<Table.Column
								title='Approved By'
								dataIndex='approvedUser'
								key='approvedUser'
								render={(approvedUser: User | undefined) =>
									approvedUser?.name ?? ''
								}
							/>
							<Table.Column
								title='Canceled By'
								dataIndex='canceledByUser'
								key='canceledByUser'
								render={(canceledByUser: User | undefined) =>
									canceledByUser?.name ?? ''
								}
							/>
						</Table>
					</Col>
				</Row>
			) : (
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			)}
		</Spin>
	);
};
