import React, {useState} from 'react';
import {Button} from 'antd';
import type {ButtonProps} from 'antd';

type MouseEvent = React.MouseEvent<HTMLElement>;

type SafeSubmitButtonProps = Omit<ButtonProps, 'onClick'> & {
	onClick?: (event: MouseEvent) => void | Promise<void>;
};

export const SafeSubmitButton: React.FC<SafeSubmitButtonProps> = ({
	onClick,
	disabled,
	children,
	...props
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleClick = async (e: MouseEvent) => {
		if (isSubmitting) {
			return;
		}

		try {
			setIsSubmitting(true);
			if (onClick) {
				await Promise.resolve(onClick(e));
			}
		} finally {
			// Reset after 2 seconds
			setTimeout(() => {
				setIsSubmitting(false);
			}, 3000);
		}
	};

	return (
		<Button
			{...props}
			disabled={disabled ?? isSubmitting}
			onClick={handleClick}
		>
			{isSubmitting ? 'Processing...' : children}
		</Button>
	);
};
