import * as React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {uiPaths} from 'app/constants';
import ViewAllWallet from 'app/components/Wallet/ViewAllWallet';
import CreateWallet from 'app/components/Wallet/CreateWallet';
import AddWalletMoney from 'app/components/Wallet/AddWalletMoney';
import ViewPaidExpense from 'app/components/PaidExpense/ViewPaidExpense';
import ViewApprovedPaidExpense from 'app/components/ExpenseReport/ViewApprovedPaidExpense';
import ViewPettyCash from 'app/components/ExpenseReport/ViewPettyCash';
import ViewPettyCashDetail from 'app/components/ExpenseReport/ViewPettyCashDetail';
import {Login} from 'app/components/Login';
import {EmailRedirect} from 'app/components/EmailRedirect';
import {ViewEmailTemplateForm} from 'app/components/EmailTemplate/ViewEmailTemplateForm';
import {ViewEmailTemplates} from 'app/components/EmailTemplate/ViewEmailTemplates';
import {DUMMI} from 'app/components/Common/DUMMI';
import {ViewProjectHandoverActivityForm} from 'app/components/ProjectHandoverActivity/ViewProjectHandoverActivityForm';
import {ViewProjectHandoverActivity} from 'app/components/ProjectHandoverActivity/ViewProjectHandoverActivity';
import {Home} from 'app/components/Home';
import {ViewPaymentMilestoneReport} from 'app/components/Reports/ViewPaymentMilestoneReport';
import {Profile} from 'app/components/Profile';
import {EditProfileView} from 'app/components/EditProfileView';
import {ViewAllUser} from 'app/components/User/ViewAllUser';
import {ViewUserForm} from 'app/components/User/ViewUserForm';
import {ViewUserDetail} from 'app/components/User/ViewUserDetail';
import {ViewAllSite} from 'app/components/Site/ViewAllSite';
import {ViewProjectCreateForm} from 'app/components/Site/ViewProjectCreateForm';
import {ViewProjectDetail} from 'app/components/Site/ViewProjectDetail';
import {ViewChangeLog} from 'app/components/ChangeLog/ViewChangeLog';
import {ViewClientQuery} from 'app/components/ClientQuery/ViewClientQuery';
import {ViewClientQueryDetail} from 'app/components/ClientQuery/ViewClientQueryDetail';
import {ViewCorporateQuery} from 'app/components/CorporateQuery/ViewCorporateQuery';
import {ViewCorporateQueryDetail} from 'app/components/CorporateQuery/ViewCorporateQueryDetail';
import {ViewProjectBudgetQuantities} from 'app/components/ProjectBudgetQuantities/ViewProjectBudgetQuantities';
import {ViewApprovalProjectBudgetQuantity} from 'app/components/Approval/ViewApprovalProjectBudgetQuantity';
import {ViewProjectBudgetQuantityForm} from 'app/components/ProjectBudgetQuantities/ViewProjectBudgetQuantityForm';
import {ChangePasswordView} from 'app/components/ChangePasswordView';
import {ViewClientDrawingSchedule} from 'app/components/DrawingSchedule/ViewClientDrawingSchedule';
import {ResetPasswordView} from 'app/components/ResetPasswordView';
import {ViewClientDrawingScheduleDetail} from 'app/components/DrawingSchedule/ViewClientDrawingScheduleDetail';
import {ViewProjectProcessMaster} from 'app/components/ProjectProcessMaster/ViewProjectProcessMaster';
import {ViewProjectProcessMasterForm} from 'app/components/ProjectProcessMaster/ViewProjectProcessMasterForm';
import {ViewScheduleDelayReport} from 'app/components/Reports/ViewScheduleDelayReport';
import {ViewScheduleActivityDelayReport} from 'app/components/Reports/ViewScheduleActivityDelayReport';
import {ViewProjectQueryReport} from 'app/components/Reports/ViewProjectQueryReport';
import {ViewInternalClientQueryReport} from 'app/components/Reports/ViewInternalClientQueryReport';
import {ViewClientQueryReportForClients} from 'app/components/Reports/ViewClientQueryReportForClients';
import {ViewApprovalWorkOrderBill} from 'app/components/Approval/ViewApprovalWorkOrderBill';
import {ViewApprovalChecklistResponse} from 'app/components/Approval/ViewApprovalChecklistResponse';
import {ViewApprovalWorkOrder} from 'app/components/Approval/ViewApprovalWorkOrder';
import {ViewApprovalMaterialQuery} from 'app/components/Approval/ViewApprovalMaterialQuery';
import {ViewHelpAndFeedback} from 'app/components/HelpAndFeedback/ViewHelpAndFeedback';
import {ViewNotification} from 'app/components/Notification/ViewNotification';
import {ViewWorkOrder} from 'app/components/WorkOrder/ViewWorkOrder';
import {ViewWorkOrderDetail} from 'app/components/WorkOrder/ViewWorkOrderDetail';
import {ViewWorkOrderBill} from 'app/components/WorkOrderBill/ViewWorkOrderBill';
import {ViewPaymentDetailForm} from 'app/components/PaymentDetail/ViewPaymentDetailForm';
import {ViewWorkOrderForm} from 'app/components/WorkOrder/ViewWorkOrderForm';
import {ViewWorkOrderBillDetail} from 'app/components/WorkOrderBill/ViewWorkOrderBillDetail';
import {ViewProjectProcessSchedule} from 'app/components/ProjectProcessSchedule/ViewProjectProcessSchedule';
import {ViewProjectProcessScheduleForm} from 'app/components/ProjectProcessSchedule/ViewProjectProcessScheduleForm';
import {ViewConstructionSchedule} from 'app/components/ProjectProcessSchedule/ViewConstructionSchedule';
import {ViewApprovalSchedule} from 'app/components/ProjectProcessSchedule/ViewApprovalSchedule';
import {ViewClientConstructionSchedule} from 'app/components/ProjectProcessSchedule/ViewClientConstructionSchedule';
import {ViewClientProjectProcessScheduleDetail} from 'app/components/ProjectProcessSchedule/ViewClientProjectProcessScheduleDetail';
import {ViewClientApprovalSchedule} from 'app/components/ProjectProcessSchedule/ViewClientApprovalSchedule';
import {ViewQuery} from 'app/components/Query/ViewQuery';
import {ViewQueryDetail} from 'app/components/Query/ViewQueryDetail';
import {ViewMaterialQuery} from 'app/components/MaterialQuery/ViewMaterialQuery';
import {ViewMaterialQueryDetail} from 'app/components/MaterialQuery/ViewMaterialQueryDetail';
import {ViewProcessChecklistResponseForm} from 'app/components/ProcessChecklistResponse/ViewProcessChecklistResponseForm';
import {ViewProcessChecklist} from 'app/components/ProcessChecklist/ViewProcessChecklist';
import {ViewProcessChecklistForm} from 'app/components/ProcessChecklist/ViewProcessChecklistForm';
import {ViewDrawingSchedule} from 'app/components/DrawingSchedule/ViewDrawingSchedule';
import {ViewDrawingScheduleForm} from 'app/components/DrawingSchedule/ViewDrawingScheduleForm';
import {ViewConstructionChecklistResponse} from 'app/components/ProcessChecklistResponse/ViewConstructionChecklistResponse';
import {ViewDesignChecklistResponse} from 'app/components/ProcessChecklistResponse/ViewDesignChecklistResponse';
import {ViewPendingRoleAssignmentReport} from 'app/components/Reports/ViewPendingRoleAssignmentReport';
import {ViewRetoolComponent} from 'app/components/ViewRetoolComponent';
import {ViewAttendance} from 'app/components/Attendance/ViewAttendance';
import {ViewMonthlyPettyCashReport} from 'app/components/Reports/ViewMonthlyPettyCashReport';
import {ViewMaterialOrderDailyQuantities} from 'app/components/MaterialOrderDailyQuantity/ViewMaterialOrderDailyQuantities';
import {ViewMaterialOrderDailyQuantityForm} from 'app/components/MaterialOrderDailyQuantity/ViewMaterialOrderDailyQuantityForm';
import {ViewScheduleManualEntry} from 'app/components/ScheduleManualEntry/ViewScheduleManualEntry';
import {ViewChecklistResponseReport} from 'app/components/Reports/ViewChecklistResponseReport';
import {ViewClientQueryDeviceUsageReport} from 'app/components/Reports/ViewClientQueryDeviceUsageReport';
import {ViewChecklistApprovalReport} from 'app/components/Reports/ViewChecklistApprovalReport';
import {ViewLabourAttendance} from 'app/components/LabourAttendance/ViewLabourAttendance';
import {ViewApprovalScheduleActivityDelayReport} from 'app/components/Reports/ViewApprovalScheduleActivityDelayReport';
import {ViewClientQueryOverdueReport} from 'app/components/Reports/ViewClientQueryOverdueReport';
import {ViewLabourAttendanceForm} from 'app/components/LabourAttendance/ViewLabourAttendanceForm';
import {ViewDailyLabourReport} from 'app/components/Reports/ViewDailyLabourReport';
import {ViewCorporateQueryReport} from 'app/components/Reports/ViewCorporateQueryReport';
import {ViewActivityTemplate} from 'app/components/ActivityTemplate/ViewActivityTemplate';
import {ViewActivityTemplateForm} from 'app/components/ActivityTemplate/ViewActivityTemplateForm';
import {ViewActivityTemplateItem} from 'app/components/ActivityTemplateItem/ViewActivityTemplateItem';
import {ViewActivityTemplateItemForm} from 'app/components/ActivityTemplateItem/ViewActivityTemplateItemForm';
import {ViewProjectActivity} from 'app/components/ProjectActivity/ViewProjectActivity';
import {ViewProjectActivityForm} from 'app/components/ProjectActivity/ViewProjectActivityForm';
import {ViewDrawingScheduleActivityReport} from 'app/components/Reports/ViewDrawingScheduleActivityReport';
import {ViewScheduleNotCompletedDelayReport} from 'app/components/Reports/ViewScheduleNotCompletedDelayReport';
import {ViewDrawingScheduleChecklistResponseReport} from 'app/components/Reports/ViewDrawingScheduleChecklistResponseReport';
import {ViewElectricityConsumption} from 'app/components/ElectricityConsumption/ViewElectricityConsumption';
import {ViewElectricityConsumptionForm} from 'app/components/ElectricityConsumption/ViewElectricityConsumptionForm';
import {ViewElectricityConsumptionReport} from 'app/components/Reports/ViewElectricityConsumptionReport';
import {ViewDesignProgressTrackerReport} from 'app/components/Reports/ViewDesignProgressTrackerReport';
import {ViewWarrantyDocument} from 'app/components/WarrantyDocument/ViewWarrantyDocument';
import {ViewWarrantyDocumentForm} from 'app/components/WarrantyDocument/ViewWarrantyDocumentForm';
import {ViewDailyScheduleDelayReport} from 'app/components/Reports/ViewDailyScheduleDelayReport';
import {ViewDailyDrawingScheduleActivityReport} from 'app/components/Reports/ViewDailyDrawingScheduleActivityReport';
import {PrintWorkOrderBill} from 'app/components/WorkOrderBill/PrintWorkOrderBill';
import {ViewTerminateWorkOrderForm} from 'app/components/WorkOrder/ViewTerminateWorkOrderForm';
import {ViewWorkOrderBalancePaymentReport} from 'app/components/Reports/ViewWorkOrderBalancePaymentReport';
import {ViewUserPermissionForm} from 'app/components/UserPermission/ViewUserPermissionForm';
import {ViewAllUserPermission} from 'app/components/UserPermission/ViewAllUserPermission';
import {ViewAllCategory} from 'app/components/Category/ViewAllCategory';
import {ViewCategoryForm} from 'app/components/Category/ViewCategoryForm';
import {ViewConstructionSchedulePhotoReport} from 'app/components/Reports/ViewConstructionSchedulePhotoReport';
import {ViewDailyScheduleDelayByStatusReport} from 'app/components/Reports/ViewDailyScheduleDelayByStatusReport';
import {ViewClientWarrantyDocument} from 'app/components/WarrantyDocument/ViewClientWarrantyDocument';
import {ViewWarrantyDocumentDetail} from 'app/components/WarrantyDocument/ViewWarrantyDocumentDetail';
import {ViewWorkOrderQuery} from 'app/components/WorkOrderQuery/ViewWorkOrderQuery';
import {ViewWorkOrderQueryDetail} from 'app/components/WorkOrderQuery/ViewWorkOrderQueryDetail';
import {ViewApprovalWorkOrderQuery} from 'app/components/WorkOrderQuery/ViewApprovalWorkOrderQuery';
import {ViewAllWarrantyPeriod} from 'app/components/WarrantyPeriod/ViewAllWarrantyPeriod';
import {ViewClientWarrantyPeriod} from 'app/components/WarrantyPeriod/ViewClientWarrantyPeriod';
import {ViewWarrantyPeriodForm} from 'app/components/WarrantyPeriod/ViewWarrantyPeriodForm';
import {ViewApprovalPaymentMilestone} from 'app/components/Approval/ViewApprovalPaymentMilestone';
import {ViewWarrantyClientQuery} from 'app/components/WarrantyQuery/ViewWarrantyClientQuery';
import {ViewWarrantyQuery} from 'app/components/WarrantyQuery/ViewWarrantyQuery';
import {ViewWarrantyQueryDetail} from 'app/components/WarrantyQuery/ViewWarrantyQueryDetail';
import {ViewWarrantyQueryReport} from 'app/components/Reports/ViewWarrantyQueryReport';
import {ViewAllMaterialPurchaseOrder} from 'app/components/MaterialPurchaseOrder/ViewAllMaterialPurchaseOrder';
import {ViewAllMaterialMaster} from 'app/components/MaterialMaster/ViewAllMaterialMaster';
import {ViewMaterialMasterForm} from 'app/components/MaterialMaster/ViewMaterialMasterForm';
import {ViewWorkOrderRemainingDebitsReport} from 'app/components/Reports/ViewWorkOrderRemainingDebitsReport';

export const HomeRoute: React.FC = () => {
	const routes = [
		{path: '/', exact: true, component: Home},
		{path: '/' + uiPaths.dummi, component: DUMMI},
		{path: '/' + uiPaths.emailRedirect, component: EmailRedirect},
		{path: '/' + uiPaths.login, component: Login},
		{path: '/' + uiPaths.forgotPassword, component: ResetPasswordView},
		{path: '/' + uiPaths.changePassword, component: ChangePasswordView},
		{path: '/' + uiPaths.editProfile, component: EditProfileView},
		{path: '/' + uiPaths.profile, component: Profile},
		{path: '/' + uiPaths.userDetail, component: ViewUserDetail},
		{path: '/' + uiPaths.notification, component: ViewNotification},
		{path: '/' + uiPaths.approvedPaidExpense, component: ViewApprovedPaidExpense},
		{path: '/' + uiPaths.pettyCash, component: ViewPettyCash},
		{path: '/' + uiPaths.pettyCashDetail, component: ViewPettyCashDetail},
		{path: '/' + uiPaths.wallet, component: ViewAllWallet},
		{path: '/' + uiPaths.user, component: ViewAllUser},
		{path: '/' + uiPaths.site, component: ViewAllSite},
		{path: '/' + uiPaths.query, component: ViewQuery},
		{path: '/' + uiPaths.queryDetail, component: ViewQueryDetail},
		{path: '/' + uiPaths.clientQuery, component: ViewClientQuery},
		{path: '/' + uiPaths.clientQueryDetail, component: ViewClientQueryDetail},
		{path: '/' + uiPaths.pendingRoleAssignmentReport, component: ViewPendingRoleAssignmentReport},
		{path: '/' + uiPaths.allProjectBudgetQuantities, component: ViewApprovalProjectBudgetQuantity},
		{path: '/' + uiPaths.projectBudgetQuantities, component: ViewProjectBudgetQuantities},
		{path: '/' + uiPaths.projectBudgetQuantityForm, component: ViewProjectBudgetQuantityForm},
		{path: '/' + uiPaths.viewEmailTemplates, component: ViewEmailTemplates},
		{path: '/' + uiPaths.editEmailTemplate, component: ViewEmailTemplateForm},
		{path: '/' + uiPaths.createEmailTemplate, component: ViewEmailTemplateForm},
		{path: '/' + uiPaths.corporateQuery, component: ViewCorporateQuery},
		{path: '/' + uiPaths.corporateQueryDetail, component: ViewCorporateQueryDetail},
		{path: '/' + uiPaths.drawingProcessSchedule, component: ViewDrawingSchedule},
		{path: '/' + uiPaths.attendance, component: ViewAttendance},
		{path: '/' + uiPaths.warrantyDocuments, component: ViewWarrantyDocument},
		{path: '/' + uiPaths.clientWarrantyDocuments, component: ViewClientWarrantyDocument},
		{path: '/' + uiPaths.editWarrantyDocument, component: ViewWarrantyDocumentForm},
		{path: '/' + uiPaths.warrantyDocumentDetail, component: ViewWarrantyDocumentDetail},
		{path: '/' + uiPaths.labourAttendance, component: ViewLabourAttendance},
		{path: '/' + uiPaths.labourAttendanceForm, component: ViewLabourAttendanceForm},
		{path: '/' + uiPaths.scheduleManualEntry, component: ViewScheduleManualEntry},
		{path: '/' + uiPaths.viewProjectHandoverSchedule, component: ViewProjectHandoverActivity},
		{path: '/' + uiPaths.editProjectHandoverSchedule, component: ViewProjectHandoverActivityForm},
		{path: '/' + uiPaths.materialOrderDailyQuantity, component: ViewMaterialOrderDailyQuantities},
		{path: '/' + uiPaths.materialOrderDailyQuantityForm, component: ViewMaterialOrderDailyQuantityForm},
		{path: '/' + uiPaths.drawingProcessScheduleForm, component: ViewDrawingScheduleForm},
		{path: '/' + uiPaths.clientDrawingProcessScheduleDetail, component: ViewClientDrawingScheduleDetail},
		{path: '/' + uiPaths.editDrawingProcessSchedule, component: ViewDrawingScheduleForm},
		{path: '/' + uiPaths.clientDrawingSchedule, component: ViewClientDrawingSchedule},
		{path: '/' + uiPaths.viewHelpAndFeedback, component: ViewHelpAndFeedback},
		{path: '/' + uiPaths.electricityConsumption, component: ViewElectricityConsumption},
		{path: '/' + uiPaths.electricityConsumptionForm, component: ViewElectricityConsumptionForm},
		{path: '/' + uiPaths.paidExpense, component: ViewPaidExpense},
		{path: '/' + uiPaths.materialQuery, component: ViewMaterialQuery},
		{path: '/' + uiPaths.materialQueryDetail, component: ViewMaterialQueryDetail},
		{path: '/' + uiPaths.projectScheduleDelayReport, component: ViewScheduleDelayReport},
		{path: '/' + uiPaths.dailyProjectScheduleDelayReport, component: ViewDailyScheduleDelayReport},
		{path: '/' + uiPaths.dailyProjectScheduleDelayByStatusReport, component: ViewDailyScheduleDelayByStatusReport},
		{path: '/' + uiPaths.drawingScheduleActivityReport, component: ViewDrawingScheduleActivityReport},
		{path: '/' + uiPaths.dailyDrawingScheduleActivityReport, component: ViewDailyDrawingScheduleActivityReport},
		{path: '/' + uiPaths.paymentMilestoneReport, component: ViewPaymentMilestoneReport},
		{path: '/' + uiPaths.designProgressTrackerReport, component: ViewDesignProgressTrackerReport},
		{path: '/' + uiPaths.checklistResponseReport, component: ViewChecklistResponseReport},
		{path: '/' + uiPaths.drawingScheduleChecklistResponseReport, component: ViewDrawingScheduleChecklistResponseReport},
		{path: '/' + uiPaths.checklistApprovalReport, component: ViewChecklistApprovalReport},
		{path: '/' + uiPaths.corporateQueryReport, component: ViewCorporateQueryReport},
		{path: '/' + uiPaths.clientQueryDeviceUsageReport, component: ViewClientQueryDeviceUsageReport},
		{path: '/' + uiPaths.clientQueryOverdueReport, component: ViewClientQueryOverdueReport},
		{path: '/' + uiPaths.projectScheduleActivityDelayReport, component: ViewScheduleActivityDelayReport},
		{path: '/' + uiPaths.electricityConsumptionReport, component: ViewElectricityConsumptionReport},
		{path: '/' + uiPaths.projectScheduleNotCompletedDelayReport, component: ViewScheduleNotCompletedDelayReport},
		{path: '/' + uiPaths.approvalScheduleActivityDelayReport, component: ViewApprovalScheduleActivityDelayReport},
		{path: '/' + uiPaths.monthlyPettyCashReport, component: ViewMonthlyPettyCashReport},
		{path: '/' + uiPaths.projectQueryReport, component: ViewProjectQueryReport},
		{path: '/' + uiPaths.oragnisationalClientQueryReport, component: ViewInternalClientQueryReport},
		{path: '/' + uiPaths.clientQueryReportForClients, component: ViewClientQueryReportForClients},
		{path: '/' + uiPaths.workOrder, component: ViewWorkOrder},
		{path: '/' + uiPaths.workOrderDetail, component: ViewWorkOrderDetail},
		{path: '/' + uiPaths.workOrderTerminate, component: ViewTerminateWorkOrderForm},
		{path: '/' + uiPaths.workOrderBill, component: ViewWorkOrderBill},
		{path: '/' + uiPaths.workOrderBillDetail, component: ViewWorkOrderBillDetail},
		{path: '/' + uiPaths.enterPaymentDetail, component: ViewPaymentDetailForm},
		{path: '/' + uiPaths.createWorkOrderFromSchedule, component: ViewWorkOrderForm},
		{path: '/' + uiPaths.allWorkOrder, component: ViewApprovalWorkOrder},
		{path: '/' + uiPaths.changeLog, component: ViewChangeLog},
		{path: '/' + uiPaths.projectProcessMaster, component: ViewProjectProcessMaster},
		{path: '/' + uiPaths.createProjectProcessMaster, component: ViewProjectProcessMasterForm},
		{path: '/' + uiPaths.editProjectProcessMaster, component: ViewProjectProcessMasterForm},
		{path: '/' + uiPaths.projectProcessSchedule, component: ViewProjectProcessSchedule},
		{path: '/' + uiPaths.createProjectProcessSchedule, component: ViewProjectProcessScheduleForm},
		{path: '/' + uiPaths.editProjectProcessSchedule, component: ViewProjectProcessScheduleForm},
		{path: '/' + uiPaths.viewProcessChecklistResponseDetail, component: ViewProcessChecklistResponseForm},
		{path: '/' + uiPaths.viewConstructionChecklistResponse, component: ViewConstructionChecklistResponse},
		{path: '/' + uiPaths.viewDesignChecklistResponse, component: ViewDesignChecklistResponse},
		{path: '/' + uiPaths.processChecklist, component: ViewProcessChecklist},
		{path: '/' + uiPaths.createProcessChecklist, component: ViewProcessChecklistForm},
		{path: '/' + uiPaths.editProcessChecklist, component: ViewProcessChecklistForm},
		{path: '/' + uiPaths.constructionSchedule, component: ViewConstructionSchedule},
		{path: '/' + uiPaths.approvalSchedule, component: ViewApprovalSchedule},
		{path: '/' + uiPaths.clientConstructionSchedule, component: ViewClientConstructionSchedule},
		{path: '/' + uiPaths.clientApprovalSchedule, component: ViewClientApprovalSchedule},
		{path: '/' + uiPaths.clientProjectProcessScheduleDetail, component: ViewClientProjectProcessScheduleDetail},
		{path: '/' + uiPaths.checklistResponseApproval, component: ViewApprovalChecklistResponse},
		{path: '/' + uiPaths.allWorkOrderBill, component: ViewApprovalWorkOrderBill},
		{path: '/' + uiPaths.allMaterialQuery, component: ViewApprovalMaterialQuery},
		{path: '/' + uiPaths.createWallet, component: CreateWallet},
		{path: '/' + uiPaths.editWallet, component: CreateWallet},
		{path: '/' + uiPaths.addWalletMoney, component: AddWalletMoney},
		{path: '/' + uiPaths.createNewUser, component: ViewUserForm},
		{path: '/' + uiPaths.editUser, component: ViewUserForm},
		{path: '/' + uiPaths.createNewSite, component: ViewProjectCreateForm},
		{path: '/' + uiPaths.editSite, component: ViewProjectDetail},
		{path: '/' + uiPaths.siteDetail, component: ViewProjectDetail},
		{path: '/' + uiPaths.dailyLabourReport, component: ViewDailyLabourReport},
		{path: '/' + uiPaths.retool, component: ViewRetoolComponent},
		{path: '/' + uiPaths.activityTemplates, component: ViewActivityTemplate},
		{path: '/' + uiPaths.addActivityTemplate, component: ViewActivityTemplateForm},
		{path: '/' + uiPaths.editActivityTemplate, component: ViewActivityTemplateForm},
		{path: '/' + uiPaths.activityTemplateItems, component: ViewActivityTemplateItem},
		{path: '/' + uiPaths.addActivityTemplateItem, component: ViewActivityTemplateItemForm},
		{path: '/' + uiPaths.editActivityTemplateItem, component: ViewActivityTemplateItemForm},
		{path: '/' + uiPaths.projectActivities, component: ViewProjectActivity},
		{path: '/' + uiPaths.addProjectActivity, component: ViewProjectActivityForm},
		{path: '/' + uiPaths.editProjectActivity, component: ViewProjectActivityForm},
		{path: '/' + uiPaths.workOrderBillPrint, component: PrintWorkOrderBill},
		{path: '/' + uiPaths.workOrderBalanceReport, component: ViewWorkOrderBalancePaymentReport},
		{path: '/' + uiPaths.addPermissions, component: ViewUserPermissionForm},
		{path: '/' + uiPaths.allPermissions, component: ViewAllUserPermission},
		{path: '/' + uiPaths.allCategories, component: ViewAllCategory},
		{path: '/' + uiPaths.addCategory, component: ViewCategoryForm},
		{path: '/' + uiPaths.editCategory, component: ViewCategoryForm},
		{path: '/' + uiPaths.constructionSchedulePhotoReport, component: ViewConstructionSchedulePhotoReport},
		{path: '/' + uiPaths.workOrderQuery, component: ViewWorkOrderQuery},
		{path: '/' + uiPaths.workOrderQueryDetail, component: ViewWorkOrderQueryDetail},
		{path: '/' + uiPaths.approvalWorkOrderQuery, component: ViewApprovalWorkOrderQuery},
		{path: '/' + uiPaths.allWarrantyPeriods, component: ViewAllWarrantyPeriod},
		{path: '/' + uiPaths.clientWarrantyPeriods, component: ViewClientWarrantyPeriod},
		{path: '/' + uiPaths.addWarrantyPeriod, component: ViewWarrantyPeriodForm},
		{path: '/' + uiPaths.editWarrantyPeriod, component: ViewWarrantyPeriodForm},
		{path: '/' + uiPaths.paymentMilestoneApproval, component: ViewApprovalPaymentMilestone},
		{path: '/' + uiPaths.warrantyClientQuery, component: ViewWarrantyClientQuery},
		{path: '/' + uiPaths.warrantyQuery, component: ViewWarrantyQuery},
		{path: '/' + uiPaths.warrantyQueryDetail, component: ViewWarrantyQueryDetail},
		{path: '/' + uiPaths.weeklyWarrantyQueryReport, component: ViewWarrantyQueryReport},
		{path: '/' + uiPaths.allMaterialPurchaseOrder, component: ViewAllMaterialPurchaseOrder},
		{path: '/' + uiPaths.allMaterialMasters, component: ViewAllMaterialMaster},
		{path: '/' + uiPaths.addMaterialMaster, component: ViewMaterialMasterForm},
		{path: '/' + uiPaths.editMaterialMaster, component: ViewMaterialMasterForm},
		{path: '/' + uiPaths.workOrderRemainingDebitsReport, component: ViewWorkOrderRemainingDebitsReport},
	];

	return (
		<HashRouter>
			<Switch>
				{routes.map((route, index) => (
					<Route
						key={index}
						exact={route.exact}
						path={route.path}
						component={route.component}
					/>
				))}
			</Switch>
		</HashRouter>
	);
};

